import React from "react";
import { useNavigate } from "react-router-dom";

function BackButton({ altBack }) {
  const navigate = useNavigate();
  return (
    <a
      onClick={() => navigate(altBack ? altBack : -1)}
      className="d-flex align-items-center gap-2"
    >
      <img src="../assets/images/backbt.png" /> {"Go back"}
    </a>
  );
}

export default BackButton;
