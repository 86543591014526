import React, { useEffect, useState } from "react";
import InputFormFile from "./../InputFormFile";
import { apiManager } from "../../../data/apiHandler";
import { toast } from "react-toastify";

import * as imageConversion from "image-conversion";
import imageCompression from "browser-image-compression";

import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import Thumbnails from "./thumbnails";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { editAdvertApiCall } from "../../../services/api";
import { addWatermark, blobToFile, checkImageResolution, getVideoCover } from "../../../data/utils";
export default function Media({
  setInputFileError,
  register,
  submit,
  InputFileError,
  uploadsInOrder,
  setUploadsInOrder,
  setInputFile,
  inputFile,
  status,
  class1,
  class2,
  adId,
  type,
  uploading,
  setUploading,
  storedList,
  callFunc,
  listOfImages,
  setListOfImages,
  userId,
  hasOrder,
}) {
  const fileTypes = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/webp",
    "image/heic",
    "image/heif",
    "video/mp4",
    "video/quicktime",
  ];
 
  const [imageList, setImageList] = useState([]);
  useEffect(() => {
    if (storedList) {
      setImageList(storedList);
    }
  }, [storedList]);

  const imageProcessingLayers = async (file) => {
    let thumbnail = "";
    let fileType = "";

    if (file.type.includes("video")) {
      fileType = "video";
      const thumb_blob_temp = await getVideoCover(file);
      thumbnail = blobToFile(thumb_blob_temp, "thumbnail");
    } else {
      fileType = "image";
      thumbnail = file;
    }

    try {
      let asyncCompression = [];
      if (fileType === "image") {
        asyncCompression.push(
          imageCompression(file, {
            maxSizeMB: 0.6,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          })
        );
      }
      asyncCompression.push(
        imageCompression(thumbnail, {
          maxSizeMB: 0.1,
          maxWidthOrHeight: 640,
          useWebWorker: true,
        })
      );

      const compressionRes = await Promise.all(asyncCompression);

      if (fileType === "image") file = blobToFile(compressionRes[0], file.name);
      thumbnail = blobToFile(
        compressionRes[fileType === "image" ? 1 : 0],
        `thumbnail_${thumbnail.name}`
      );
    } catch (e) {
      console.log(
        "Could not compress, proceeding with uncompressed file.\n",
        e
      );
    }

    try {
      let asyncWM = [];

      // add watermark
      if (file.type.includes("image")) {
        asyncWM.push(addWatermarkToFile(file));
      }
      asyncWM.push(addWatermarkToFile(thumbnail));

      const wmRes = await Promise.all(asyncWM);

      if (file.type.includes("image")) file = wmRes[0];
      thumbnail = wmRes[fileType === "image" ? 1 : 0];
    } catch (e) {
      console.log("Problem adding watermark", e);
    }

    return {
      file: file,
      thumbnail: thumbnail,
    };
  };

  const handleAdImageUpload = async (files) => {
    const formData = new FormData();
    const formDataThumbnail = new FormData();
    let asyncCalls = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      asyncCalls.push(imageProcessingLayers(file));
    }

    const processedImages = await Promise.all(asyncCalls);

    processedImages.forEach((pair) => {
      formData.append(`files`, pair.file);
      formDataThumbnail.append(`files`, pair.thumbnail);
    });

    const allApiCalls = await Promise.all([
      apiManager(
        "POST",
        `storage/upload?lang=${"en"}&type=${type}&adId=${
          adId ? adId : ""
        }&userId=${userId ? userId : ""}`,
        formData,
        null,
        "multipart/form-data"
      ),
      apiManager(
        "POST",
        `storage/upload?lang=${"en"}&type=${type}&adId=${
          adId ? adId : ""
        }&userId=${userId ? userId : ""}`,
        formDataThumbnail,
        null,
        "multipart/form-data"
      ),
    ]).catch((err) => {
      toast.error(err.message);
      console.log(err);
    });
    const file_response = allApiCalls[0];
    const thumbnail_response = allApiCalls[1];

    if (file_response.status) {
      const allFiles = file_response?.data;
      const allThumbnails = thumbnail_response?.data;
      let justUploaded = [];
      const max_id =
        uploadsInOrder?.length > 0
          ? Math.max(...uploadsInOrder.map((v) => v?.id || null))
          : 0;
      for (let i = 0; i < allFiles.length; i++) {
        justUploaded.push({
          ...allFiles[i],
          id: max_id + i + 1,
          thumbnail: allThumbnails?.[i]?.s3URL,
          thumbnail_id: allThumbnails?.[i]?._id,
        });
      }
      const order = [...uploadsInOrder, ...justUploaded];
      // saveCarWithoutVinFormSubmit(
      //   { uploadsInOrder: { uploads: order } },
      //   false,
      //   adId
      // );
      editAdvertApiCall({id: adId, data: {uploadsInOrder: { uploads: order }}})

      setUploadsInOrder(order);
      setImageList([...imageList, file_response?.data[0]]);
      // setListOfImages?.([...imageList, file_response?.data[0]]);
      setInputFile([]);
      // setInputFile([...inputFile, file]);
      callFunc?.();
    } else {
      toast.error(file_response.message);
    }
  };
  const handleKycDocUpload = async (file) => {
    const formData = new FormData();
    formData.append("files", file);
    const allApiCalls = await Promise.all([
      apiManager(
        "POST",
        `storage/upload?lang=${"en"}&type=${type}&userId=${
          userId ? userId : ""
        }`,
        formData,
        null,
        "multipart/form-data"
      ),
    ]).catch((err) => {
      toast.error(err.message);
      console.log(err);
    });

    const file_response = allApiCalls[0];

    if (file_response.status) {
      let newArr = [file_response?.data[0]?.s3URL];
      setUploadsInOrder([...uploadsInOrder, file_response?.data[0]]);

      setImageList([...imageList, file_response?.data[0]]);
      setListOfImages?.([...imageList, file_response?.data[0]]);
      setInputFile([]);
      setInputFile([...inputFile, file]);
      callFunc?.();
    } else {
      toast.error(file_response.message);
    }
  };
  const addWatermarkToFile = async (file) => {
    const fileDimension = await checkImageResolution(URL.createObjectURL(file));

    const wm = await imageConversion.compress(
      await imageConversion.urltoBlob("/assets/images/watermark_logo.png"),
      {
        quality: 1,
        height: fileDimension.height * 0.15,
        type: "image/png",
      }
    );

    const blob_wm = await addWatermark(file, URL.createObjectURL(wm));
    file = new File([blob_wm], file.name, { type: blob_wm.type });

    return file;
  };

  const selectFile = async (event) => {
    if (!event?.target?.files.length) {
      return;
    }
    if (event?.target?.files[0].size > 150000000) {
      setInputFileError("File upload with valid size");
      return;
    } else if (
      !fileTypes.includes(event?.target?.files?.[0].type) &&
      !event?.target?.files?.[0].type == ""
    ) {
      setInputFileError("File upload with valid file type");
      return;
    } else {
      let files = event?.target?.files;

      setInputFileError("");
      // setUploading(true);

      switch (type) {
        case 6:
          await handleAdImageUpload(files);
          break;
        case 8:
          await handleKycDocUpload(files);
      }

      // setUploading(false);
    }
  };
  const removeIndex = async (i, obj) => {
    console.log(i, obj);
    if (!uploading) {
      // setUploading(true);

      let allDeleteCalls = {};
      if (type === 6) {
        allDeleteCalls = await Promise.all([
          apiManager("DELETE", `storage/upload?lang=en&id=${obj?._id}`), // main image delete
          apiManager(
            "DELETE",
            `storage/upload?lang=en&id=${obj?.thumbnail_id}`
          ), // thumbnail delete
        ]).catch((err) => {
          toast.error(err.message);
          console.error(err);
        });
      } else {
        allDeleteCalls = await Promise.all([
          apiManager("DELETE", `storage/upload?lang=en&id=${obj?._id}`), // main image delete
        ]).catch((err) => {
          toast.error(err.message);
          console.error(err);
        });
      }

      const main_res = allDeleteCalls[0];

      if (main_res.status) {
        const order = uploadsInOrder.filter((_, index) => index !== i);
        const file = inputFile.filter((_, index) => index !== i);

        if (type === 6) {
          // saveCarWithoutVinFormSubmit(
          //   { uploadsInOrder: { uploads: order } },
          //   false,
          //   adId
          // );
          editAdvertApiCall({id: adId, data: {uploadsInOrder: { uploads: order }}})
        }
        // toast.success(res.message)
        setInputFile(file);
        setUploadsInOrder(order);
        // const urlArr = uploadedFiles.filter((_, index) => index !== i);
        const itemArr = imageList.filter((_, index) => index !== i);

        // setUploadedFiles(urlArr);
        setImageList(itemArr);
      } else {
        toast.error(main_res.message);
      }
      // setUploading(false);
    }
  };

  const [dragging, setDragging] = useState({ loading: false, id: "" });

  const getIndex = (id) => uploadsInOrder.findIndex((img) => img.id === id);
  const handleDragOver = (e) => {
    const { active, over } = e;
    if (active.id === over.id) return;
    setUploadsInOrder((uploadsInOrder) => {
      return arrayMove(uploadsInOrder, getIndex(active.id), getIndex(over.id));
    });
  };

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 120,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (e) => {
    const { active } = e;
    console.log(active.id);
    setDragging({ loading: true, id: active.id });
  };
  const handleDragEnd = () => {
    setDragging(null);
  };

  return (
    <div class="other_div other_div2">
      <h3>{status == "false" ? "" : "Upload Media" + " *"}</h3>
      <div class="Information">
        <ul class={class1}>
          <li class={class2}>
            <div class="st uploaddetaildiv">
              {uploading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <i class="fa-solid fa-plus"></i>
              )}
              <InputFormFile
                multiple
                onChange={selectFile}
                event={register}
                accept={fileTypes.join(", ")}
                name={"file"}
                disabled={uploading}
              />
            </div>
          </li>
        </ul>
        <ul className={class1 + " row"}>
          <DndContext
            sensors={sensors}
            onDragOver={handleDragOver}
            // onDragEnd={handleDragEnd}
            collisionDetection={closestCorners}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
          >
            <SortableContext items={uploadsInOrder}>
              {uploadsInOrder &&
                uploadsInOrder.length > 0 &&
                uploadsInOrder?.map((item, i) => {
                  return (
                    <div
                      className={`col-4 ${
                        type === 6 ? "col-md-3 col-xxl-2" : ""
                      } mb-3`}
                    >
                      <Thumbnails
                        pos={i}
                        index={item.id}
                        item={item?.thumbnail || item?.s3URL}
                        removeIndex={removeIndex}
                        dragging={dragging}
                        obj={item}
                      />
                      {hasOrder && (
                        <div
                          style={{ backgroundColor: "#4c7ee8" }}
                          className="rounded-bottom text-light text-center"
                        >
                          {i + 1}
                        </div>
                      )}
                    </div>
                  );
                })}
            </SortableContext>
          </DndContext>
        </ul>
        {/* <h6 class="text-start w-100">
          {t("Allowed file formats: JPG, PNG")}
          <span>
            {t("Optimal size:")} <b>{t("900*600px")}</b>
          </span>
        </h6> */}
        <div class="row justify-content-end">
          {/* <button
          class="btn btn-sm-light border mx-3"
          style={{width: '120px', height: '40px', fontSize: '12px'}}
          onClick={(e) => props?.handleUploadDocument(e)}
          disabled={props?.uploading}
        >
          {props?.uploading ? t('Please wait...') : t("Upload Media")}
        </button> */}
        </div>
      </div>
      {InputFileError && (
        <p
          style={{
            fontSize: "13px",
            textAlign: "left",
            color: "red",
          }}
        >
          {InputFileError}
        </p>
      )}
    </div>
  );
}
