import React from "react";
import NumberInput from "../../Tools/NumberInput";
import SelectOptions from "../SelctOptions";
import { isFieldRequired } from "../../Pages/EditAdvert/addFormUtils";
import SelectForm from "../../Tools/SelectForm";
export default function RentPrice({
  register,
  errors,
  data,
  back,
  upload_list,
  setValue,
}) {
  return (
    <div class="other_div other_div2 sp2 row">
      <h3 className="mb-4">{"What’s Your Asking Price?"}</h3>
      {isFieldRequired("dollorPrice", "RentYourCar") && (
        <div className="mb-4">
          <h5 style={{ fontSize: "16px", fontWeight: 500 }}>
            {"Price in dollar"}
          </h5>
          <div class={`form-group ${back == false ? "rentPriceMargin" : ""}`}>
            <div class="form-control">
              <label style={{ marginTop: "0px" }}>{"Daily"}*</label>
              <NumberInput
                left={true}
                value={data?.askPrice}
                event={register}
                name={"dollorDaily"}
                placeholder={"$"}
              />
              {errors.dollorDaily && <p>{errors.dollorDaily?.message}</p>}
            </div>
          </div>
          <div
            class={`form-group decrement ${
              back == false ? "rentPriceMargin" : ""
            }`}
          >
            <div class="form-control">
              <label style={{ marginTop: "0px" }}>{"Weekly"}*</label>
              <NumberInput
                left={true}
                value={data?.askPrice1}
                event={register}
                name={"dollorWeekly"}
                placeholder={"$"}
              />
              {errors.dollorWeekly && <p>{errors.dollorWeekly?.message}</p>}
            </div>
          </div>
          <div
            class={`form-group decrement ${
              back == false ? "rentPriceMargin" : ""
            }`}
          >
            <div class="form-control">
              <label style={{ marginTop: "0px" }}>{"Monthly"}*</label>
              <NumberInput
                left={true}
                value={data?.askPrice2}
                event={register}
                name={"dollorMonthly"}
                placeholder={"$"}
              />
              {errors.dollorMonthly && <p>{errors.dollorMonthly?.message}</p>}
            </div>
          </div>
        </div>
      )}
      <div>
        {/* <h3 className="mb-3">{t("What’s Your Asking Price?") + " (IQD) "}*</h3> */}
        <h5 style={{ fontSize: "16px", fontWeight: 500 }}>
          {"Price in iraqi dinar"}*
        </h5>
        <div class={`form-group ${back == false ? "rentPriceMargin" : ""}`}>
          <div class="form-control">
            <label style={{ marginTop: "0px" }}>{"Daily"}*</label>
            <NumberInput
              left={true}
              value={data?.askPrice}
              event={register}
              name={"daily_price"}
              placeholder={"IQD"}
            />
            {errors.daily_price && <p>{errors.daily_price?.message}</p>}
          </div>
        </div>
        <div
          class={`form-group decrement ${
            back == false ? "rentPriceMargin" : ""
          }`}
        >
          <div class="form-control">
            <label style={{ marginTop: "0px" }}>{"Weekly"}*</label>
            <NumberInput
              left={true}
              value={data?.askPrice1}
              event={register}
              name={"weekly_price"}
              placeholder={"IQD"}
            />
            {errors.weekly_price && <p>{errors.weekly_price?.message}</p>}
          </div>
        </div>
        <div
          class={`form-group decrement ${
            back == false ? "rentPriceMargin" : ""
          }`}
        >
          <div class="form-control">
            <label style={{ marginTop: "0px" }}>{"Monthly"}*</label>
            <NumberInput
              left={true}
              value={data?.askPrice2}
              event={register}
              name={"monthly_price"}
              placeholder={"IQD"}
            />
            {errors.monthly_price && <p>{errors.monthly_price?.message}</p>}
          </div>
        </div>
        {data?.owner?.userType === "brand" && (
          <div className="mb-4">
            <h3 className="mb-1">{"Ad Label"}</h3>
            {/* <SelectOptions
                 noAll
                 onChange={(e) => {
                   if (e?.target?.value == "None") {
                     setValue("adLabel", "");
                   } else {
                     setValue("adLabel", e?.target?.value);
                   }
                 }}
                 style={"form-control"}
                 event={register}
                 name={"adLabel"}
                 selectedOption={data?.properties?.adLabel}
                 values={upload_list?.adLabels}
               /> */}
            <SelectForm
              style={"form-control m-0"}
              event={register}
              name={"adLabel"}
              selectedOption={data?.properties?.finance}
              values={upload_list?.adLabels}
            />
            {errors.adLabel && <p>{errors.adLabel.message}</p>}
          </div>
        )}
      </div>
    </div>
  );
}
