import React from "react";

function CopyKeywords({ list }) {
  const [showCopied, setShowCopied] = React.useState();
  const handleClick = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setShowCopied(text);
      setTimeout(() => {
        setShowCopied(null);
      }, 700);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <div className="d-flex gap-2 flex-wrap ">
      {list.map((item, index) => {
        return (
          <div className="d-flex flex-column align-items-center">
            <button
              onClick={() => handleClick(item)}
              className="btn btn-sm btn-light border"
              key={item + index}
            >
              {item}
            </button>
            <span className="text-info" style={{ fontSize: "12px", minHeight: "20px" }}>
              {showCopied === item ? "Copied" : ""}
            </span>
          </div>
        );
      })}
    </div>
  );
}

export default CopyKeywords;

