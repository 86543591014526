import React, { useState } from "react";
import Select from "react-select";
import { changeAccountType } from "../../../services/api";
import swal from "sweetalert";
import { Icon } from "@iconify/react/dist/iconify.js";
function ChangeType({ account, refreshPage, handleCloseModal }) {
  const [loading, setLoading] = useState(false);
  const options = [
    {
      label: "Brand",
      value: "brand",
    },
    {
      label: "Dealer",
      value: "dealer",
    },
    {
      label: "Private user",
      value: "user",
    },
  ];
  const [value, setValue] = useState(
    options?.filter((v) => v?.value === account?.userType)?.[0]
  );
  const handleSubmit = () => {
    setLoading(true);
    changeAccountType({
      userType: value.value,
      userId: account?._id,
    }).then((res) => {
      if (res?.status) {
        handleCloseModal();
        refreshPage();
        swal("Success", res?.message, "success");
      } else {
        swal("Error", res?.message, "error");
      }
      setLoading(false);
    });
  };
  return (
    <div className="d-flex flex-column gap-3">
      <label
        htmlFor="changeAccountType"
        className="fw-semibold"
        style={{ fontSize: "18px" }}
      >
        Account types
      </label>
      <Select
        inputId="changeAccountType"
        options={options}
        value={value}
        components={{
          // DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          NoOptionsMessage: () => null,
          LoadingIndicator: () => null,
          ClearIndicator: () => null,
        }}
        onChange={(e) => setValue(e)}
      />
      <div className="d-flex justify-content-end">
        <button
          className="themeButtonYes m-0"
          style={{ width: 90 }}
          onClick={() => handleSubmit()}
          disabled={loading || account?.userType === value?.value}
        >
          {loading ? (
            <Icon
              icon="line-md:loading-twotone-loop"
              width="24"
              height="24"
              style={{ color: "white" }}
            />
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </div>
  );
}

export default ChangeType;
