import React from "react"; // Import React instead of React namespace
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import MiniBox from "../MiniBox";
import Skeletons2 from "../Skeletons/Skeletons2";
import { Icon } from "@iconify/react/dist/iconify.js";
import { generatePagination } from "../../../data/utils";
import LazyLoad from "react-lazy-load";

export default function BasicTable({
  itemperpage,
  columns,
  rows,
  actions,
  view,
  actionType,
  rowInformation,
  dropDownImage,
  currentPage,
  setCurrentPage,
  totalPages,
  submitting,
  customAction,
  onRowClick,
  localCurrPageKey,
}) {
  const itemsPerPage = itemperpage ? itemperpage : 10; // Change this to the desired number of items per page
  // Calculate the start and end indices for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const setCurPage = (val) => {
    localStorage.setItem(localCurrPageKey, val);
    setCurrentPage(val);
  };

  //pagination previous button
  const previous = () => {
    let page = currentPage;
    if (page > 1) setCurPage(page - 1);
  };

  //pagination next button
  const next = () => {
    let page = currentPage;
    if (page < totalPages) setCurPage(page + 1);
  };
  // let buttonArray = [];
  // for (let i = 1; i <= totalPages; i++) {
  //   if (totalPages > 6) {
  //     if (
  //       (i >= currentPage - 2 && i <= currentPage + 2) ||
  //       i > totalPages - 3
  //     ) {
  //       buttonArray.push(i);
  //     }
  //   } else {
  //     buttonArray.push(i);
  //   }
  // }
  // if (totalPages > 6) {
  //   const ind = buttonArray.length / 2 + 1;
  //   buttonArray.splice(ind, 0, "...");
  // }

  const checkOptional = (row, str) => {
    switch (str) {
      case "allowedToLogin":
        if (row?.allowedToLogin) {
          return true;
        } else {
          return false;
        }
    }
  };

  return (
    <div>
      <TableContainer component={Paper} style={{ minHeight: "400px" }}>
        {submitting ? (
          <Skeletons2 lines={7} />
        ) : (
          <>
            <Table
              sx={{ minWidth: 650, position: "relative" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {columns.length > 0 &&
                    columns.map((item, index) =>
                      index === 0 ? (
                        <TableCell
                          className={item.style}
                          key={index}
                          scope="col"
                        >
                          <strong className="text-nowrap">
                            {item.label.toUpperCase()}
                          </strong>
                        </TableCell>
                      ) : (
                        <TableCell
                          className={item.style}
                          scope="col"
                          align="right"
                          key={index}
                        >
                          <strong className="text-nowrap">
                            {item.label.toUpperCase()}
                          </strong>
                        </TableCell>
                      )
                    )}
                  {view && (
                    <TableCell>
                      <strong>{"VIEW"}</strong>
                    </TableCell>
                  )}
                  {customAction && (
                    <TableCell>
                      <strong>{customAction?.title}</strong>
                    </TableCell>
                  )}
                  {actions && actions.length > 0 && (
                    <TableCell>
                      <strong>{"ACTIONS"}</strong>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              {rows.length > 0 ? (
                <TableBody>
                  {rows.length > 0 &&
                    rows.map((row, rowIndex) => (
                      <TableRow
                        key={rowIndex} // Use a unique key for each row
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          backgroundColor:
                            row?.allowedToLogin == false 
                            // || row?.rawData?.display == false
                              ? "#ffc4c4"
                              : "",
                        }}
                      >
                        {columns.map((item, columnIndex) =>
                          columnIndex === 0 ? (
                            <TableCell
                              component="th"
                              scope="row"
                              onClick={(e) => onRowClick?.(row)}
                              role={onRowClick ? "button" : ""}
                            >
                              {item.navigate ? (
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                  to={`${item.path}?id=${row._id}`}
                                >
                                  {row[item.name]}
                                </Link>
                              ) : (
                                row[item.name]
                              )}
                            </TableCell>
                          ) : (
                            <TableCell
                              align="right"
                              className={`position-relative`}
                              style={item.name == "status" ? row.style : {}}
                              onClick={(e) => onRowClick?.(row)}
                              role={onRowClick ? "button" : ""}
                            >
                              {item.navigate ? (
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                  to={`${item.path}?id=${row._id}`}
                                >
                                  {row[item.name]
                                    ? row[item.name]
                                    : row[item.name] == 0
                                    ? "0"
                                    : "--"}
                                </Link>
                              ) : item.type == "image" ? (
                                <span class="img">
                                  <LazyLoad
                                    height={30}
                                    threshold={0.95}
                                    onContentVisible={() => {
                                      console.log("loaded!");
                                    }}
                                  >
                                    <img
                                      className={item.style}
                                      src={row[item.name]}
                                    />
                                  </LazyLoad>
                                </span>
                              ) : row[item.name] ? (
                                row[item.name]
                              ) : row[item.name] == 0 ? (
                                "0"
                              ) : (
                                "--"
                              )}{" "}
                              {item?.verified &&
                                row[item.name] &&
                                row?.[item?.verifiedKey] && (
                                  <div
                                    className={
                                      "h-100 d-flex align-items-center"
                                    }
                                  >
                                    <Icon
                                      icon="ic:baseline-verified"
                                      style={{
                                        alignSelf: "center",
                                        color: "#4C7EE8",
                                        fontSize: "16px",
                                      }}
                                    />
                                  </div>
                                )}
                              {/* Convert the object to a string */}
                            </TableCell>
                          )
                        )}
                        {view && (
                          <TableCell>
                            {!view?.disabled ? (
                              <Link
                                className="view"
                                to={`${view.path}?id=${row._id}${
                                  view?.moreParams || ""
                                }`}
                                onClick={() => view?.onclick?.(row)}
                                state={{ item: row }}
                              >
                                <img
                                  src={`assets/images/${view.image}`}
                                  alt="icon"
                                />
                                {view.label}
                              </Link>
                            ) : (
                              <Link className="view">
                                <img
                                  src={`assets/images/${view.image}`}
                                  alt="icon"
                                />
                                {view.label}
                              </Link>
                            )}
                          </TableCell>
                        )}
                        {customAction && (
                          <TableCell>
                            <a
                              className="view"
                              onClick={() => customAction?.onclick(row)}
                            >
                              {customAction?.label}
                            </a>
                          </TableCell>
                        )}
                        {actionType == "dropdown" &&
                          actions &&
                          actions.length > 0 && (
                            <TableCell>
                              <DropdownButton
                                className="btn"
                                title={
                                  <img
                                    src={
                                      dropDownImage
                                        ? dropDownImage
                                        : "assets/images/dash_img/threedot.png"
                                    }
                                    alt="icon"
                                  />
                                }
                              >
                                {actions.map((actionItem, actionIndex) => {
                                  if (actionItem?.optional) {
                                    if (
                                      !checkOptional(
                                        row,
                                        actionItem?.optionalString
                                      )
                                    ) {
                                      return;
                                    }
                                  }
                                  return (
                                    <Dropdown.Item
                                      key={actionIndex}
                                      onClick={() =>
                                        actionItem.onclick(row._id, row)
                                      }
                                    >
                                      {actionItem.label}
                                    </Dropdown.Item>
                                  );
                                })}
                              </DropdownButton>
                            </TableCell>
                          )}
                        {!actionType && actions && actions.length > 0 && (
                          <TableCell>
                            {actions.map((actionItem, actionIndex) => (
                              <a
                                class={actionItem.style}
                                style={actionItem.inlineStyle}
                                key={actionIndex}
                                onClick={() =>
                                  actionItem.onclick(row._id, row?.rawData)
                                }
                              >
                                {actionItem.type != "text" && (
                                  <img
                                    src={`../assets/images/${actionItem.image}`}
                                    alt="image"
                                  />
                                )}
                                {actionItem.label}
                              </a>
                            ))}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              ) : (
                <>
                  <h5
                    className="text-center w-100"
                    style={{ position: "absolute", top: "70px" }}
                  >
                    No records available
                  </h5>
                </>
              )}
            </Table>
          </>
        )}
      </TableContainer>
      {rowInformation && rowInformation.length > 0 && (
        <div className="indiv">
          {rowInformation.map((item, index) => (
            <MiniBox
              key={index}
              style="st"
              heading={item.count || ""}
              paragraph={item.content}
            />
          ))}
        </div>
      )}
      <div class="text-right">
        <nav aria-label="Page navigation example">
          <ul class="pagination mb-0 float-end">
            <li class="page-item" onClick={() => previous()}>
              <a class="page-link">
                <i class="fa-solid fa-chevron-left"></i>
              </a>
            </li>
            {generatePagination(totalPages, currentPage).map((item, index) => (
              <li class="page-item">
                <a
                  value={item}
                  onClick={(e) => {
                    if (item !== "..") {
                      setCurPage(item);
                    }
                  }}
                  class={`page-link ${currentPage === item ? "active" : ""}`}
                >
                  {item}
                </a>
              </li>
            ))}
            <li class="page-item" onClick={() => next()}>
              <a class="page-link">
                <i class="fa-solid fa-chevron-right"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
