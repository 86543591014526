import React, { useEffect, useState } from "react";
import { Nav, NavItem } from "reactstrap";
import {
  getFeatureName,
  getNameFromVal,
  numberWithCommas,
} from "../../../data/utils";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import { getFeatures } from "../../../services/api";
import { isFieldRequired } from "../../Pages/EditAdvert/addFormUtils";

export default function CarDetailModel({ data, title, page }) {
  const [tabType, setTabType] = useState("basicDetails");
  const [allDetails, setAllDetails] = useState(null);
  const [featuresData, setFeaturesData] = useState([]);
  const lang = "en"

  const setTitle = (i) => {
    switch (i) {
      case 0:
        return "Basic Details";
      case 1:
        return "Specifications";
      case 2:
        return "Features";
    }
  };
  const getRequests = () => {
    getFeatures().then((res) => {
      if (res?.status) {
        // setFeaturesData(res?.data?.features)
        let reqFeatures = {};
        const features = res?.data?.features;
        features.forEach((v) => {
          if (data?.features?.[v?.value]) {
            reqFeatures[v?.name?.[lang]] = true;
          } else {
            reqFeatures[v?.name?.[lang]] = false;
          }
        });
        setAllDetails({
          basicDetails: { ...data?.basicDetails, cityPlate: data?.cityPlate },
          specifications: data?.specifications,
          features: reqFeatures,
        });
      }
    });
  };
  useEffect(() => {
    getRequests();
  }, []);
  const makeValue = (name, value) => {
    if (name === "carDescription") {
      return null;
    }
    if (name === "anyThingElse") {
      return null;
    }
    if (name === "engineSize") {
      if (!value) return null;
      const obj = name.replace("engine", "").toLowerCase();
      return (
        <li className="list-group-item d-flex justify-content-between align-items-center">
          {getFeatureName(name)}
          <span className="text-end">
            {value ? getNameFromVal(obj, value, lang) : "--"}
          </span>
        </li>
      );
    }
    if (name === "enginePower") {
      if (!value) return null;
      return (
        <li className="list-group-item d-flex justify-content-between align-items-center">
          {getFeatureName(name)}
          <span className="text-end">
            {value ? `${numberWithCommas(value)} ${"bhb"}` : "--"}
          </span>
        </li>
      );
    }
    if (name === "km") {
      if (!value) return null;
      return (
        <li className="list-group-item d-flex justify-content-between align-items-center">
          {"Kilometres"}
          <span className="text-end">
            {value ? `${numberWithCommas(value)} ${"KMs"}` : "--"}
          </span>
        </li>
      );
    }
    const type = typeof value;
    switch (type) {
      case "boolean":
        return value ? (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            {name}{" "}
            <span>
              <img
                src="assets/images/check.webp"
                className="text-end"
                height={15}
                alt=""
              />
            </span>
          </li>
        ) : null;
      case "string":
        if (!value) return null;
        return (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            {getFeatureName(name)}
            <span className="text-end">
              {value && value?.length > 0 ? value : "--"}
            </span>
          </li>
        );
      case "number":
        if (value !== 0 && !value) return null;
        return (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            {getFeatureName(name)}
            <span className="text-end">
              {value || value == 0 ? value : "--"}
            </span>
          </li>
        );
      case "object":
        if (!value?.[lang]) return null;
        return (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            {getFeatureName(name)}
            <span className="text-end">
              {value?.[lang] ? value?.[lang] : "--"}
            </span>
          </li>
        );
    }
  };

  const [accordion, setAccordion] = useState([]);
  const toggleAccordion = (e) => {
    if (accordion.includes(e)) {
      setAccordion([...accordion.filter((v) => v !== e)]);
    } else {
      setAccordion([...accordion, e]);
    }
  };

  return (
    <div className="container p-0">
      <div className="col-lg-5">
        <Nav
          className="custom-tabs row"
          style={{ marginInline: "unset" }}
          pills
          fill
        >
          <NavItem
            role="button"
            className="col-4"
            active={tabType == "basicDetails"}
            onClick={() => setTabType("basicDetails")}
          >
            {setTitle(0)}
          </NavItem>
          <NavItem
            role="button"
            className="col-4"
            active={tabType == "specifications"}
            onClick={() => setTabType("specifications")}
          >
            {setTitle(1)}
          </NavItem>
          {isFieldRequired("features", page) && (
            <NavItem
              role="button"
              className="col-4"
              active={tabType == "features"}
              onClick={() => setTabType("features")}
            >
              {setTitle(2)}
            </NavItem>
          )}
        </Nav>
      </div>
      <div className=" specifications" style={{ maxWidth: "unset" }}>
        <ul
          className="d-none d-sm-block w-100"
          style={{ marginBottom: "70px" }}
        >
          {allDetails &&
            Object.keys(allDetails?.[tabType]).map((val) => {
              let v = allDetails?.[tabType]?.[val];
              if (v === "true") v = true;
              if (v === "false") v = false;
              const valu = makeValue(val, v);
              return valu;
            })}
        </ul>
        <div
          className="d-block d-sm-none list-group"
          style={{ listStyleType: "none" }}
        >
          {allDetails &&
            Object.keys(allDetails?.[tabType]).map((val) => {
              let v = allDetails?.[tabType]?.[val];
              if (v === "true") v = true;
              if (v === "false") v = false;
              const valu = makeValue(val, v);
              return valu;
            })}
        </div>
      </div>
    </div>
  );
}

// <div
//   class="modal fade"
//   id="exampleModal"
//   tabindex="-1"
//   aria-labelledby="exampleModalLabel"
//   aria-hidden="true"
// >
//   <div class="modal-dialog specifications">
//     <div class="modal-content" style={{ paddingTop: "18px" }}>
//       <div class="modal-header d-flex justify-content-end">
//         <a class="btnclose mb-3" data-bs-dismiss="modal" aria-label="Close">
//           <img
//             src="../assets/images/close_img.png"
//             alt="icon"
//             height={22}
//           />
//         </a>
//       </div>
//       <div class="modal-body">
//         <div className="specifications">
//           <Accordion open={accordion} toggle={toggleAccordion}>
//             {allDetails &&
//               allDetails?.map((item, index) => {
//                 return (
//                   <AccordionItem>
//                     <AccordionHeader targetId={index + 1}>
//                       <img class="plus" src="../assets/images/plus.png" />
//                       <img
//                         style={{ display: "none" }}
//                         src="../assets/images/cls.png"
//                       />
//                       {setTitle(index)}
//                     </AccordionHeader>
//                     <AccordionBody accordionId={index + 1}>
//                       <ul className="d-none d-sm-block w-100">
//                         {Object.keys(item).map((val) => {
//                           let v = item?.[val];
//                           if (v === "true") v = true;
//                           if (v === "false") v = false;
//                           const valu = makeValue(val, v);
//                           return valu;
//                         })}
//                       </ul>
//                       <div
//                         className="d-block d-sm-none list-group"
//                         style={{ listStyleType: "none" }}
//                       >
//                         {Object.keys(item).map((val) => {
//                           let v = item?.[val];
//                           if (v === "true") v = true;
//                           if (v === "false") v = false;
//                           const valu = makeValue(val, v);
//                           return valu;
//                         })}
//                       </div>
//                     </AccordionBody>
//                   </AccordionItem>
//                 );
//               })}
//           </Accordion>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
