import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function SelectedAccounts({
  allFilter,
  setAllFilter,
  selectUnselectUser,
  getName,
  className
}) {
  if (allFilter?.users?.length === 0) return;
  return (
    <div className={className || ""}>
      <div
        role="button"
        className="d-flex justify-content-between aling-items-center px-1"
        onClick={(e) =>
          setAllFilter({ ...allFilter, users: [], usersData: [] })
        }
      >
        <span className="fw-semibold">Selected Accounts</span>
        <span
          className="text-danger"
          style={{
            fontSize: "12px",
            fontWeight: 700,
          }}
        >
          Remove all
        </span>
      </div>
      <div
        className="d-flex flex-wrap gap-2 border p-3"
        style={{ borderRadius: "8px" }}
      >
        {allFilter.usersData.map((user, i) => {
          return (
            <h5 className="d-flex gap-2 justify-content-between align-items-center border px-2 py-1 rounded-pill mb-0">
              <span style={{ fontSize: "14px", fontWeight: 400 }}>
                {getName(user)}
              </span>
              <Icon
                role="button"
                className="rounded-circle"
                icon="charm:cross"
                fontSize={13}
                onClick={(e) => selectUnselectUser(false, user?._id)}
                style={{ color: "white", backgroundColor: "#4c7ee8" }}
              />
            </h5>
          );
        })}
      </div>
    </div>
  );
}
