import React, { useState } from "react";

//form imports
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextareaWithAttatch from "../SendNotification/TextareaWithAttatch";
import { Input } from "reactstrap";
import { langs } from "../../../data/data";
import { tr } from "../../../data/translations";

export default function Confirm({
  data,
  handleSubmit,
  closeModal,
  selectedFile,
  setSelectedFile,
  setStatusWithoutMail,
  preferredLang
}) {
  const [lang, setLang] = useState(preferredLang || "en");

  const schema = Yup.object().shape({
    // title: Yup.string().required("This field is required"),
    body: Yup.string().required("This field is required"),
    key: Yup.string(),
    type: Yup.string(),
    lang: Yup.string(),
  });
  const {
    control,
    getValues,
    setValue,
    handleSubmit: handleSubmitConfirm,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      key: data?.key,
      type: data?.type,
      lang: lang
    },
    mode: "onBlur",
  });
  return (
    <div className="row">
      <p>{data?.description}</p>
      {/* <div>
        <Controller
          control={control}
          name="title"
          render={({ field }) => (
            <Input
              type="text"
              className=""
              placeholder="Subject"
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onChange}
            />
          )}
        />
        {errors.title && <p className="helperText">{errors.title.message}</p>}
        <br />
      </div> */}
      <div className="d-flex gap-3 mb-2">
        {langs.map((item, i) => {
          return (
            <label
              htmlFor={`sendOnInput${i}`}
              key={i}
              role="button"
              style={{ fontSize: "15px" }}
              className="d-flex align-items-center"
            >
              <input
                id={`sendOnInput${i}`}
                type="radio"
                checked={lang === item?.value}
                value={item?.value}
                className="me-1"
                onClick={(e) => {
                  setValue("lang", item?.value)
                  setLang(item?.value);
                }}
              />
              {item?.label}
            </label>
          );
        })}
      </div>
      <TextareaWithAttatch
        id={'confirmInput'}
        rtl={lang !== "en" ? true : false}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        control={control}
        name={"body"}
        errors={errors}
        textareaPlaceholder={tr[lang]["Write your text..."]}
        showAttatch={false}
        textareaHeight={"300px"}
        attatchStyles={{ top: "260px", left: "12px", position: "absolute" }}
      />
      <div className="d-flex justify-content-end mt-4">
        <button
          type="button"
          className="themeButtonNo"
          onClick={() => closeModal()}
        >
          No
        </button>
        <button
          type="button"
          className="themeButtonYes"
          onClick={handleSubmitConfirm(handleSubmit)}
        >
          Yes
        </button>
        {/* {data?.type == "approve" && ( */}
        <button
          type="button"
          style={{ width: "150px" }}
          className="themeButtonYes bg-light text-black border"
          onClick={() => setStatusWithoutMail({key: getValues("key"), lang: lang})}
        >
          Continue without Text
        </button>
        {/* )} */}
      </div>
    </div>
  );
}
