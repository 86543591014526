import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

function ViewFullscreen({ children, url, disabled }) {
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState("");

  const openModal = () => {
    setImage(url);
    setModal(true);
  };
  const handleCloseModal = () => {
    setImage("");
    setModal(false);
  };

  return (
    <div role={!disabled ? "button" : ""} onClick={!disabled ? openModal : null}>
      {children}
      <Modal
        size="lg"
        isOpen={modal}
        fullscreen
        centered
        toggle={handleCloseModal}
      >
        <ModalHeader
          style={{ alignSelf: "end" }}
          toggle={handleCloseModal}
          className="d-flex justify-content-end border-0"
        ></ModalHeader>
        <ModalBody
          className="d-flex justify-content-center align-items-center"
          style={{ position: "relative", padding: "0xp" }}
        >
          <div className="my-image-gallery">
            <img
              style={{
                objectFit: "contain",
                width: "100%",
                height: "100vh",
              }}
              src={image ? image : "../assets/images/notFound.png"}
              alt="bg"
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ViewFullscreen;
