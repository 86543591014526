import { Icon } from "@iconify/react";
import React, { useEffect, useRef } from "react";
import Select from "react-select";

const SelectCustomItems = ({
  items1,
  items2,
  title1,
  title2,
  selectedOption,
  placeholder,
  handelChange,
  showDropdownIcon,
  width,
  hasSelected,
  hasAny,
}) => {
  // selectedOption = {...selectedOption, label: selectedOption?.name}
  const language = localStorage.getItem("cl");
  const formatOptionLabel = ({ label, image }) => (
    <div
      style={{ display: "flex", justifyContent: "space-between" }}
      className="w-100"
    >
      <div
        style={{
          display: "-webkit-box",
          WebkitLineClamp: 1,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
      >
        {label}
      </div>
      <div>
        <img src={image} width={20} alt="" />
      </div>
    </div>
  );

  let options1 = items1?.map?.((v) => ({
    item: v,
    label: v?.name,
    value: v?.name,
    image: v?.image,
  }));
  let options2 = items2?.map?.((v) => ({
    item: v,
    label: v?.name,
    value: v?.name,
    image: v?.image,
  }));

  // if (hasAny) {
  //   const any = {
  //     item: {},
  //     label: t("Any"),
  //     value: "Any",
  //     image: "",
  //   };
  //   options1?.unshift?.(any);
  //   options2?.unshift?.(any);
  // }
  let options = [];
  if (options1 && options2) {
    options = [
      { label: title1, options: options1 || [] },
      { label: title2, options: options2 || [] },
    ];
  } else {
    options = options1 || options2;
  }

  const selectInputRef = useRef();
  useEffect(() => {
    if (!selectedOption) {
      selectInputRef?.current?.clearValue();
    }
  }, [selectedOption]);

  const customStyles = {
    menu: (base) => ({
      ...base,
      width: `${width} !important`,
    }),
    groupHeading: (base) => ({
      ...base,
      textAlign: language === "English" ? "left" : "right",
    }),
  };

  // if (hasSelected && !selectedOption) {
  //   return <></>;
  // }
  return (
    <div style={{ position: "relative" }}>
      <Select
        ref={selectInputRef}
        // menuIsOpen={true}
        isSearchable={false}
        {...{
          defaultValue: {
            ...selectedOption,
            label: selectedOption?.name,
            value: selectedOption?.name,
          },
        }}
        placeholder={placeholder}
        onChange={handelChange}
        formatOptionLabel={formatOptionLabel}
        options={options || []}
        className="basic-multi-select"
        classNamePrefix="selectCustom "
        styles={customStyles}
        menuPlacement="auto"
        // menuPosition="fixed"
        classNames={{
          control: (state) =>
            state.isFocused ? "form-control" : "form-control",
          menu: (state) =>
            state.isFocused ? "selectCustomMenu" : "selectCustomMenu",
        }}
      />
      {showDropdownIcon && (
        <Icon
          icon="icon-park-outline:down"
          width={17}
          className="fw-bolder"
          style={{
            color: "#404040",
            // zIndex: 9999,
            position: "absolute",
            top: "15px",
            // right: i18n.language === "English" ? "5px" : "unset",
            // left: i18n.language !== "English" ? "5px" : "unset",
            right: "5px",
            left: "unset",
          }}
        />
      )}
    </div>
  );
};

export default SelectCustomItems;
