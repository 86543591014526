export const getFieldNames = (step) => {
  switch (step) {
    case 1:
      return [
        "make",
        "model",
        "makeYear",
        "regional",
        "condition",
        "colour",
        "interiorColor",
      ];
    case 2:
      return [
        "paint",
        "kilometers",
        "transmission",
        "fuel",
        "body",
        "doors",
        "seats",
      ];
    case 3:
      return ["brandNew"];
    case 4:
      return [
        "city",
        "asking_price",
        "daily_price",
        "weekly_price",
        "monthly_price",
      ];
  }
};

export const hideSpec = {
  cylinder: ["Electric"],
  engineSize: ["Electric"],
  batterySize: ["Bi Fuel", "Diesel", "Petrol", "Gas"],
};



export const sellFields = [
  "make",
  "model",
  "makeYear",
  "regional",
  "condition",
  "colour",
  "interiorColor",
  "paint",
  "transmission",
  "fuel",
  "body",
  "kilometers",
  "doors",
  "seats",
  "features",
  "haveSomethingElse",
  "carDescription",
  "brandNew",

  "plateCityPlateNumber",
  "plateCity",
  "plateType",
  "plateNumber",

  "dollorPrice",
  "asking_price",

  "sellerType"
];
export const rentFields = [
  "make",
  "model",
  "makeYear",
  // "regional",
  // "condition",
  "colour",
  "interiorColor",
  // "paint",
  "transmission",
  "fuel",
  "body",
  // "kilometers",
  "doors",
  "seats",
  // "features",
  // "haveSomethingElse",
  "carDescription",
  // "brandNew",

  // "plateCityPlateNumber",
  // "plateCity",
  // "plateType",
  // "plateNumber",

  // "dollorPrice",
  "daily_price",
  "weekly_price",
  "monthly_price",

  // "sellerType"
];

const sellNames = ["SellYourCar", "normal"];
const rentNames = ["RentYourCar", "rental"];

export const isFieldRequired = (field, page) => {
  if (sellNames.includes(page)) {
    return sellFields.includes(field);
  } else if (rentNames.includes(page)) {
    return rentFields.includes(field);
  }
};
