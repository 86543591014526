import getAlluser from "./users.json";
import metricsFunApi from "./metrics.json";
import keyStatsFunApi from "./keyStats.json";
import overviewFunApi from "./overview.json";
import pagesFunApi from "./pages.json";
import blockWordsFunApi from "./block_words.json";
import singleUserPendingApprovalApi from "./singleUserDetails.json";
import editPageApi from "./editpage.json";
import pendingApprovalActionApi from "./actionPendingAprroval.json";
import pendingAprrovalDeleteApi from "./deletePendingAprroval.json";
import deletePageAPi from "./deletePages.json";
import userAccountListApi from "./user_accounts.json";
import reportApi from "./report.json";
import pricingApi from "./pricing.json";
import editSubscriptionApi from "./editSubscription.json";
import addSubscriptionApi from "./addSubscription.json";
import accountDetailsApi from "./accountDetails.json";
import trasncationDeleteApi from "./transcationDelete.json";
import getUsersApi from "./sendNotificationUser.json";
import getBannerImageApi from "./bannerImages.json";
import graphApi from "./graph.json";
import swal from "sweetalert";
import myprofile from "./myprofile.json";
import login from "./login.json";
import { apiManager } from "../data/apiHandler";
// 12 date

export const getProfiledata = (data) => {
  // const response = myprofile;
  // return response;
  const apiCall = apiManager("GET", `user/profile?lang=en`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
  return apiCall;
};
//
export const getUserApi = ({ page, limit, userType }) => {
  // const response = getAlluser;
  // return response;
  const apiCall = apiManager(
    "GET",
    `admin/kycs?lang=en&page=${page || ""}&limit=${limit || ""}&userType=${
      userType || ""
    }`
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};

export const getMetricsApi = async (time) => {
  try {
    const response = metricsFunApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const getKeyStatsApi = async (time) => {
  try {
    const response = keyStatsFunApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const getOverviewApi = async (time) => {
  try {
    const response = overviewFunApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const getPagesApi = async () => {
  try {
    const response = pagesFunApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const getBlockWordApi = async (time) => {
  try {
    const response = blockWordsFunApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const getSingleUserPendingApprovalFun = async (body) => {
  try {
    const response = singleUserPendingApprovalApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const editPageApiFun = async (body) => {
  try {
    const response = editPageApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const pendingApprovalActionFun = async (body) => {
  try {
    const response = await pendingApprovalActionApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};
export const deletePendingAprrovalFun = async (body) => {
  try {
    const response = await pendingAprrovalDeleteApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const deletePagesFun = async (body) => {
  try {
    const response = await deletePageAPi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const userAccountListFun = async ({
  page,
  limit,
  name,
  userType,
  accountType,
}) => {
  const apiCall = apiManager(
    "GET",
    `admin/users?lang=en&page=${page || ""}&limit=${limit || ""}&searchTerm=${
      name || ""
    }&userType=${userType || ""}&accountType=${accountType || ""}`
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });

  return apiCall;
};
export const getAdTypes = () => {
  let addd = localStorage.getItem("adTypeData");
  if (addd) {
    const ad = JSON.parse(addd);
    return ad;
  }
  const apiCall = apiManager("GET", `general/adType?lang=${"en"}`)
    .then((res) => {
      if (res.status) {
        localStorage.setItem("adTypeData", JSON.stringify(res));
        return res;
      }
    })
    .catch((err) => {
      console.error(err);
      // toast.error(err.message);
    });
  return apiCall;
};
export const reportFun = async (body) => {
  try {
    const response = await reportApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const getPricingFun = async ({ adType, type }) => {
  let adTypeId = "";
  const adData = await getAdTypes();

  if (adType == "Sell") {
    adTypeId = adData.data.filter((v) => v.name.en === "Sell")[0]._id;
  }
  if (adType == "Rent") {
    adTypeId = adData.data.filter((v) => v.name.en === "Rent")[0]._id;
  }
  const apiCall = apiManager(
    "GET",
    `admin/subscriptions?lang=en&adType=${adTypeId || ""}&type=${type || ""}`
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};

export const editSubscriptionFun = async (body) => {
  try {
    const response = await editSubscriptionApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const addSubscriptionFun = async (body) => {
  try {
    const response = await addSubscriptionApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const getAccountDetailsFun = async (body) => {
  // try {
  //   const response = await accountDetailsApi;
  //   return response;
  // } catch (e) {
  //   console.log("error", e);
  // }

  const apiCall = apiManager(
    "GET",
    `admin/userDetails?lang=en&id=${body?.id ? body?.id : ""}`
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
  return apiCall;
};

export const trasncationDeleteFun = async (body) => {
  try {
    const response = await trasncationDeleteApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const getUsersFun = async ({ userType, name }) => {
  // try {
  //   const response = getUsersApi;
  //   return response;
  // } catch (e) {
  //   console.log("error", e);
  // }
  const apiCall = apiManager(
    "GET",
    `admin/users?lang=en&limit=500&userType=${userType || ""}&searchTerm=${
      name || ""
    }`
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};

export const getBannerImageFun = async () => {
  try {
    const response = getBannerImageApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};
export const graphFun = async (time) => {
  try {
    const response = graphApi;
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const submitLoginForm = async (formData) => {
  //   try {
  //     const response = login;
  //     return response;
  //   } catch (e) {
  //     console.log("error", e);
  //   }
  console.log(formData);
  const apiCall = await apiManager(
    "POST",
    `auth/login?lang=en&signInType=`,
    formData
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });

  return apiCall;
};

export const getUserTransactions = async ({ id, page, limit, status }) => {
  const apiCall = apiManager(
    "GET",
    `user/transactions?lang=en&userId=${id || ""}&page=${page || ""}&limit=${
      limit || ""
    }&status=${status || ""}`
  )
    .then((res) => {
      if (res.status) {
        return res;
      } else {
        console.log(res);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};

export const getUserAdverts = async ({ id, page, limit, status }) => {
  let statusVal = "";
  switch (status) {
    case "active":
      statusVal = "&sold=NO&display=ON";
      break;
    case "sold":
      statusVal = "&sold=YES";
      break;
  }
  const apiCall = apiManager(
    "GET",
    `opp/filteredAds?lang=en&ownerId=${id || ""}&page=${page || ""}&limit=${
      limit || ""
    }${statusVal}`
  )
    .then((res) => {
      if (res.status) {
        return res;
      } else {
        console.log(res);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};

export const getStats = ({ userId, type }) => {
  const apiCall = apiManager(
    "GET",
    `user/states?lang=en&userId=${userId || ""}&type=${type || ""}`
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};

export const getStatusList = () => {
  const apiCall = apiManager("GET", `general/kycStatus?lang=en`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};

export const setKycStatus = (data) => {
  const apiCall = apiManager("PUT", `admin/verifyKyc?lang=en`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err.message);
    });
  return apiCall;
};

export const uploadToStorage = (data, type) => {
  const apiCall = apiManager(
    "POST",
    `storage/upload?lang=en&type=${type}`,
    data,
    null,
    "multipart/form-data"
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};

export const removeFromStorage = (id) => {
  const apiCall = apiManager("DELETE", `storage/upload?lang=en&id=${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};
export const sendPushNotification = (data) => {
  const apiCall = apiManager("POST", `admin/sendPushNotification?lang=en`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};
export const sendPushNotificationToUnregistered = (data) => {
  const apiCall = apiManager("POST", `admin/sendBulkNotification?lang=en`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};
export const sendSMS = (data) => {
  const apiCall = apiManager("POST", `admin/sendSms?lang=en`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};
export const sendEmail = (data) => {
  const apiCall = apiManager("POST", `admin/sendMultipleEmails?lang=en`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};
export const sendWhatsAppMessage = (data, lang) => {
  const apiCall = apiManager("POST", `admin/sendWhatsAppSms?lang=${lang}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};
export const updateProfile = (data) => {
  const apiCall = apiManager("POST", `admin/updateUserProfile?lang=en`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};

const statsListURL = {
  overview: "admin/dashboardStats/overview",
  dealer: "admin/dashboardStats/dealer",
  user: "admin/dashboardStats/user",
  brand: "admin/dashboardStats/brand",
};
export const getDashboardData = async ({ filterName }) => {
  const commonParams = `lang=en`;
  if (filterName) {
    let calls = [];

    calls.push(
      apiManager("GET", `${statsListURL[filterName]}?${commonParams}`)
    );
    const apiCall = await Promise.all(calls).catch((err) => {
      console.log("Filters list error: ", err);
    });

    return { [filterName]: apiCall[0]?.makes || apiCall[0]?.data || [] };
  } else {
    const apiCall = await Promise.all([
      ...Object.values(statsListURL)
        .slice(0, 9)
        .map((v) => apiManager("GET", `${v}?${commonParams}`)),
    ]).catch((err) => {
      console.log("Stats list error: ", err);
    });

    const posKey = (i) => Object.keys(statsListURL)[i];

    let res = {};
    apiCall.forEach((r, i) => {
      if (r?.status) {
        res[posKey(i)] = r?.data || [];
      }
    });

    return res;
  }
};
export const updateSubscriptionPlan = (data, id) => {
  const apiCall = apiManager("PUT", `admin/subscription?lang=en&id=${id}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};
export const editProfile = (data) => {
  const apiCall = apiManager("PUT", `user/profile?lang=en`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};
export const changePassword = (data) => {
  const apiCall = apiManager("PUT", `user/changePassword?lang=en`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};
export const toggleAdDisplay = (data, adId) => {
  const apiCall = apiManager("PUT", `opp/ad?lang=en&adId=${adId}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};

export const getInvoiceUrl = (id) => {
  const apiCall = apiManager(
    "GET",
    `storage/getLink?lang=${"en"}&transactionId=${id}`
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return apiCall;
};

export const getMake = async ({ isLive, limit, ownerId, page, filters }) => {
  const live = isLive ? "1" : "0";
  let adTypeId = "";
  const adData = await getAdTypes();

  if (page == "normal") {
    adTypeId = adData.data.filter((v) => v.name.en === "Sell")[0]._id;
  }
  if (page == "rental") {
    adTypeId = adData.data.filter((v) => v.name.en === "Rent")[0]._id;
  }

  const apiCall = apiManager(
    "GET",
    `general/makes?lang=${"en"}&limit=${
      limit ? limit : "200"
    }&adType=${adTypeId}&isLiveAds=${live || ""}&ownerId=${
      ownerId ? ownerId : ""
    }`
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
  return apiCall;
};

export const getEntityTypes = () => {
  // let entTypes = localStorage.getItem("entityTypeData");
  // if (entTypes) {
  //   const ent = JSON.parse(entTypes);
  //   return ent;
  // }

  const apiCall = apiManager("GET", `general/entityType?lang=${"en"}`)
    .then((res) => {
      localStorage.setItem("entityTypeData", JSON.stringify(res));
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return apiCall;
};

export const getModels = async ({ makeId, live, ownerId, page }) => {
  if (makeId === "Any" || makeId === "All") makeId = "";

  let adTypeId = "";
  const adData = await getAdTypes();
  if (page == "normal") {
    adTypeId = adData.data.filter((v) => v.name.en === "Sell")[0]._id;
  }
  if (page == "rental") {
    adTypeId = adData.data.filter((v) => v.name.en === "Rent")[0]._id;
  }

  const apiCall = apiManager(
    "GET",
    `opp/getModelNames?lang=${"en"}&makeId=${makeId}&isLiveAds=${
      live ? "1" : "0"
    }&ownerId=${ownerId ? ownerId : ""}&adType=${page ? adTypeId : ""}`
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
    });
  return apiCall;
};

export const getVariants = ({ make, model }) => {
  const apiCall = apiManager(
    "GET",
    `opp/allModelTrims?lang=${"en"}&makeId=${make || ""}&modelName=${
      model || ""
    }`
  )
    .then((res) => {
      if (res?.status) {
        return res?.data;
      } else {
        return [];
      }
    })
    .catch((err) => {
      console.log(err);
      return [];
    });

  return apiCall;
};

export const getGlobalSettings = () => {
  const apiCall = apiManager("GET", `admin/settings?lang=en`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      swal("Error");
    });

  return apiCall;
};

export const setGlobalSettings = (data) => {
  const apiCall = apiManager("POST", `admin/settings?lang=en`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      swal("Error");
      console.log(err);
    });
  return apiCall;
};

export const getFeatures = async () => {
  const apiCall = apiManager("GET", `opp/getAllFeatures?lang=${"en"}&limit=100`)
    .then((res) => {
      if (res?.status) {
        return res;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
  return apiCall;
};
export const getColors = async () => {
  const apiCall = apiManager("GET", `opp/getAllColors?lang=${"en"}&limit=100`)
    .then((res) => {
      if (res?.status) {
        return res;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
  return apiCall;
};
export const getYears = async () => {
  const apiCall = apiManager("GET", `admin/getAllYears?lang=${"en"}&limit=200`)
    .then((res) => {
      if (res?.status) {
        return res;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
  return apiCall;
};

export const editAdvertApiCall = async ({ id, data }) => {
  const apiCall = apiManager("PUT", `opp/ad?lang=${"en"}&adId=${id}`, data)
    .then((res) => {
      if (res?.status) {
        return res;
      } else {
        console.log(res, "error");
      }
    })
    .catch((err) => {
      console.log(err);
    });

  return apiCall;
};

export const sendOnlyRejectMail = ({ data, lang }) => {
  const apiCall = apiManager(
    "POST",
    `admin/sendRejectionEmail?lang=${lang}`,
    data
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
  return apiCall;
};

export const delistAccount = ({ id }) => {
  const apiCall = apiManager("GET", `admin/delistAd?lang=en&userId=${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err.message);
      return err;
    });
  return apiCall;
};

export const getAllCities = async (name) => {
  const apiCall = apiManager(
    "GET",
    `general/allCities?lang=${"en"}&name=${name || ""}`
  )
    .then((res) => {
      if (res?.status) {
        return res;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
      return null;
    });

  return apiCall;
};

export const getInteriorColors = async () => {
  const apiCall = apiManager(
    "GET",
    `opp/getAllInteriorColors?lang=${"en"}&limit=100`
  )
    .then((res) => {
      if (res?.status) {
        return res;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
  return apiCall;
};

export const changeAccountType = (data) => {
  const apiCall = apiManager("POST", `admin/convertUsers?lang=en`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
  return apiCall;
};
export const setContactAsVerified = (userId) => {
  const apiCall = apiManager(
    "POST",
    `admin/updateVerification?lang=en&userId=${userId || ""}`,
    {}
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
  return apiCall;
};

export const removeAccountFromDatabase = (userId) => {
  const apiCall = apiManager(
    "POST",
    `admin/deleteUserFromPlatform?lang=en&userId=${userId || ""}`,
    {}
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });
  return apiCall;
};
