import React, { useState } from "react";
import { Input } from "reactstrap";
//form imports
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "../../Tools/FormInput";
import { Icon } from "@iconify/react/dist/iconify.js";
import * as imageConversion from "image-conversion";
import { apiManager } from "../../../data/apiHandler";
import swal from "sweetalert";
import Button from "../../Tools/Button";

function EditMakeModal({ submitCallback, data, loading }) {
  const { type, item } = data;
  const [image, setImage] = useState(type === "edit" ? item?.image : "");
  const schema = Yup.object().shape({
    name: ["add", "edit"].includes(type)
      ? Yup.string().required("This field is required")
      : Yup.string(),
    image: ["add", "edit"].includes(type)
      ? Yup.string().required("Image is required")
      : Yup.string(),
    modelName:
      type === "add-model"
        ? Yup.string().required("Model name is required")
        : Yup.string(),
  });

  const [imageLoading, setImageLoading] = useState(false);
  const {
    setValue,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues:
      type === "edit"
        ? {
            name: item?.name,
            image: item?.image,
          }
        : {},
  });
  const onSubmit = (data) => {
    submitCallback(data);
  };
  const onError = (data) => {
    console.log(data);
  };

  const handleChangeImage = async (e) => {
    const file = e?.target?.files?.[0];
    setImageLoading(true);
    const formData = new FormData();
    try {
      const compressedBlob = await imageConversion.compress(file, {
        width: 300,
        height: 300,
      });
      formData.append(
        "files",
        new File([compressedBlob], file.name, {
          type: compressedBlob.type,
          lastModified: Date.now(),
        })
      );
      console.log("compressed");
    } catch (e) {
      formData.append("files", file);
      console.log("compressed error not compressed", e);
    }

    apiManager(
      "POST",
      `storage/upload?lang=${"en"}&type=${2}`,
      formData,
      null,
      "multipart/form-data"
    )
      .then((res) => {
        if (res?.status) {
          setImage(res?.data[0]?.s3URL);
          setValue("image", res?.data[0]?.s3URL);
        } else {
          swal("Error", res.message, "error");
        }
        setImageLoading(false);
      })
      .catch((err) => {
        console.log(err);
        swal("Error", err.message, "error");
      });
  };

  return (
    <div>
      {["add", "edit"].includes(type) && (
        <>
          <p>
            <label className="fw-semibold fs-6">Make Name</label>
            <FormInput
              intype={"text"}
              event={register}
              name={"name"}
              style={"form-control"}
              placeholder={"Name"}
            />
            {errors.name && <p className="helperText">{errors.name.message}</p>}
          </p>
          <p className="d-flex justify-content-center position-relative">
            {imageLoading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "300px", width: "300px" }}
              >
                <div class="spinner-border h-50 w-50" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <p className="">
                {image && (
                  <p>
                    <Icon
                      role="button"
                      icon="material-symbols:delete-sharp"
                      fontSize={34}
                      style={{
                        color: "#437ee8",
                        position: "absolute",
                        right: "0px",
                        top: "10px",
                      }}
                      onClick={(e) => {
                        setImage("");
                        setValue("image", "");
                      }}
                    />
                  </p>
                )}
                {image ? (
                  <img src={image} height={300} width={300} style={{ objectFit: "contain"}} />
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "300px", width: "300px" }}
                  >
                    <label htmlFor="makeImageInput">
                      <Icon
                        role="button"
                        icon="solar:upload-bold"
                        fontSize={100}
                        style={{ color: "#437ee8" }}
                      />
                      <input
                        id="makeImageInput"
                        type="file"
                        hidden
                        onChange={handleChangeImage}
                      />
                    </label>
                  </div>
                )}
              </p>
            )}
          </p>
          {errors.image && <p className="helperText">{errors.image.message}</p>}
        </>
      )}
      {type === "delete" && (
        <div>
          <h5>Are you sure you want to delete make {item?.name}</h5>
        </div>
      )}
      {["add", "edit"].includes(type) && (
        <Button
          disabled={loading}
          onClick={handleSubmit(onSubmit, onError)}
          style={"themeButton float-end"}
          title={
            loading ? (
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Submit"
            )
          }
        />
      )}
      {type === "delete" && (
        <div>
          <Button
            onClick={() => submitCallback()}
            disabled={loading}
            style={"themeButtonNo float-end"}
            title={
              loading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Delete"
              )
            }
          />
        </div>
      )}
      {type === "add-model" && (
        <>
          <p>
            <label className="fw-semibold fs-6">Model Name</label>
            <FormInput
              intype={"text"}
              event={register}
              name={"modelName"}
              style={"form-control"}
              placeholder={"Name"}
            />
            {errors.modelName && <p className="helperText">{errors.modelName.message}</p>}
          </p>
          <Button
            onClick={handleSubmit(onSubmit, onError)}
            disabled={loading}
            style={"themeButton float-end"}
            title={
              loading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Submit"
              )
            }
          />
        </>
      )}
    </div>
  );
}

export default EditMakeModal;
