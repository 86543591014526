export const formatDashboardData = (data) => {
  const [brand, user, dealer] = [
    data?.overviewBrand,
    data?.overviewUser,
    data?.overviewDealer,
  ];
  const valid = (val) => {
    return val || "0";
  };
  const fixDigits = (val) => {
    if (val && !isNaN(val)) {
      return val.toFixed(2);
    } else {
      return "0";
    }
  };
  const formattedData = {
    overview: {
      primaryOverview: [
        {
          title: "Total views",
          name: "totalViews",
          value: brand?.totalViews + dealer?.totalViews + user?.totalViews,
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: brand?.totalViews,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: dealer?.totalViews,
              badgeColor: "#437ee8",
            },
            {
              title: "Private",
              value: user?.totalViews,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total users",
          name: "totalUsers",
          value: brand?.totalUsers + dealer?.totalUsers + user?.totalUsers,
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: brand?.totalUsers,
              badgeColor: "#437ee8",
            },

            {
              title: "Dealer",
              value: dealer?.totalUsers,
              badgeColor: "#437ee8",
            },
            {
              title: "Private",
              value: user?.totalUsers,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total active ads",
          name: "totalActiveAds",
          value:
            brand?.totalActiveAds +
            dealer?.totalActiveAds +
            user?.totalActiveAds,
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: brand?.totalActiveAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: dealer?.totalActiveAds,
              badgeColor: "#437ee8",
            },

            {
              title: "Private",
              value: user?.totalActiveAds,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total active ads (Sell)",
          name: "totalActiveAdsSell",
          value: data?.overview?.totalSellAds,
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: brand?.totalSellAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: dealer?.totalSellAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Private",
              value: user?.totalSellAds,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total active ads (Rent)",
          name: "totalActiveAdsRent",
          value: data?.overview?.totalRentedAds,
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: brand?.totalRentedAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: dealer?.totalRentedAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Private",
              value: user?.totalRentedAds,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total chats sent",
          name: "totalChats",
          value: user?.totalChatInit + dealer?.totalChatInit + brand?.totalChatInit,
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: brand?.totalChatInit,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: dealer?.totalChatInit,
              badgeColor: "#437ee8",
            },
            {
              title: "Private",
              value: user?.totalChatInit,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total chats recieved",
          name: "totalChats",
          value:
            user?.totalReceivedChats +
            dealer?.totalReceivedChats +
            brand?.totalReceivedChats,
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: brand?.totalReceivedChats,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: dealer?.totalReceivedChats,
              badgeColor: "#437ee8",
            },
            {
              title: "Private",
              value: user?.totalReceivedChats,
              badgeColor: "#437ee8",
            },
          ],
        },
        // {
        //   title: "Total calls send",
        //   name: "totlaCalls",
        //   value:
        //     user?.totalPhoneCalls +
        //     dealer?.totalPhoneCalls,
        //   hasMore: true,
        //   more: [
        //     {
        //       title: "Private",
        //       value: user?.totalPhoneCalls,
        //       badgeColor: "#437ee8",
        //     },
        //     {
        //       title: "Dealer",
        //       value: dealer?.totalPhoneCalls,
        //       badgeColor: "#437ee8",
        //     },
        //   ],
        // },
        {
          title: "Total calls recieved",
          name: "totlaCalls",
          value: user?.userCallReceives + dealer?.dealerCallReceives + brand?.brandCallReceives,
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: brand?.brandCallReceives,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: dealer?.dealerCallReceives,
              badgeColor: "#437ee8",
            },
            {
              title: "Private",
              value: user?.userCallReceives,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total VIN searches",
          name: "totalVinSearches",
          value:
            brand?.totalVinTransactions +
            dealer?.totalVinTransactions +
            user?.totalVinTransactions,
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: brand?.totalVinTransactions,
              badgeColor: "#437ee8",
            },

            {
              title: "Dealer",
              value: dealer?.totalVinTransactions,
              badgeColor: "#437ee8",
            },
            {
              title: "Private",
              value: user?.totalVinTransactions,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total Pending Ads",
          name: "totalPendingAds",
          value: data?.overview?.totalPendingAds,
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: brand?.totalPendingAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: dealer?.totalPendingAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Private",
              value: user?.totalPendingAds,
              badgeColor: "#437ee8",
            },
          ],
        },
      ],
      soldAdsOverview: [
        {
          title: "Total ads sold",
          name: "totalAdsSold",
          value: user?.soldAds + dealer?.soldAds + brand?.soldAds,
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: brand?.soldAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: dealer?.soldAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Private",
              value: user?.soldAds,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total ads sold within platform",
          name: "totalAdsSoldWithinPlatform",
          value: user?.soldWithinPlatform + dealer?.soldWithinPlatform + brand?.soldWithinPlatform,
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: brand?.soldWithinPlatform,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: dealer?.soldWithinPlatform,
              badgeColor: "#437ee8",
            },
            {
              title: "Private",
              value: user?.soldWithinPlatform,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total ads sold outside platform",
          name: "totalAdsSoldOutsidePlatform",
          value: user?.soldOutsidePlatform + dealer?.soldOutsidePlatform + brand?.soldOutsidePlatform,
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: brand?.soldOutsidePlatform,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: dealer?.soldOutsidePlatform,
              badgeColor: "#437ee8",
            },
            {
              title: "Private",
              value: user?.soldOutsidePlatform,
              badgeColor: "#437ee8",
            },
          ],
        },
      ],
      advertsOverview: [
        {
          title: "Total ads created",
          name: "totalAds",
          value: brand?.allAds + dealer?.allAds + user?.allAds,
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: brand?.allAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: dealer?.allAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Private",
              value: user?.allAds,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total Inactive Ads",
          name: "totalInactiveAds",
          value:
            brand?.totalInactiveAds +
            dealer?.totalInactiveAds +
            user?.totalInactiveAds,
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: brand?.totalInactiveAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: dealer?.totalInactiveAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Private",
              value: user?.totalInactiveAds,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total (Pending Payment)",
          name: "incompleteAds",
          value:
            brand?.incompleteAds + dealer?.incompleteAds + user?.incompleteAds,
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: brand?.incompleteAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: dealer?.incompleteAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Private",
              value: user?.incompleteAds,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total Ads Delisted",
          name: "deletedAds",
          value: brand?.deletedAds + dealer?.deletedAds + user?.deletedAds,
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: brand?.deletedAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: dealer?.deletedAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Private",
              value: user?.deletedAds,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total ads expired",
          name: "deletedAds",
          value: user?.expiredAds + dealer?.expiredAds,
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: brand?.expiredAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: dealer?.expiredAds,
              badgeColor: "#437ee8",
            },

            {
              title: "Private",
              value: user?.expiredAds,
              badgeColor: "#437ee8",
            },
          ],
        },
      ],
      paymentsOverview: [
        {
          title: "Total payment recieved through ad postings",
          name: "totalViews",
          value: `$${fixDigits(
            data?.overview?.totalPaymentRecieved?.totalAdsSum
          )}`,
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: `$${fixDigits(brand?.totalPaymentRecieved?.totalAdsSum)}`,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: `$${fixDigits(dealer?.totalPaymentRecieved?.totalAdsSum)}`,
              badgeColor: "#437ee8",
            },
            {
              title: "Private",
              value: `$${fixDigits(user?.totalPaymentRecieved?.totalAdsSum)}`,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total payment recieved through VIN searches",
          name: "totalViews",
          value: `$${fixDigits(
            data?.overview?.totalPaymentRecieved?.totalVinSum
          )}`,
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: `$${fixDigits(brand?.totalPaymentRecieved?.totalVinSum)}`,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: `$${fixDigits(dealer?.totalPaymentRecieved?.totalVinSum)}`,
              badgeColor: "#437ee8",
            },
            {
              title: "Private",
              value: `$${fixDigits(user?.totalPaymentRecieved?.totalVinSum)}`,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total payment received",
          name: "totalPaymentRecieved",
          value: `$${fixDigits(
            data?.overview?.totalPaymentRecieved?.totalVinSum +
              data?.overview?.totalPaymentRecieved?.totalAdsSum
          )}`,
          currency: "$",
          hasMore: true,
          more: [
            {
              title: "Brand",
              value: `$${fixDigits(
                brand?.totalPaymentRecieved?.totalVinSum +
                  brand?.totalPaymentRecieved?.totalAdsSum
              )}`,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: `$${fixDigits(
                dealer?.totalPaymentRecieved?.totalVinSum +
                  dealer?.totalPaymentRecieved?.totalAdsSum
              )}`,
              badgeColor: "#437ee8",
            },
            {
              title: "Private",
              value: `$${fixDigits(
                user?.totalPaymentRecieved?.totalVinSum +
                  user?.totalPaymentRecieved?.totalAdsSum
              )}`,
              badgeColor: "#437ee8",
            },
          ],
        },
      ],
    },
    keyStats: {
      dealer: {
        stats: [
          {
            title: "Ultimate",
            name: "ultimate",
            value: data?.keyStats_dealer?.ultimate,
          },
          {
            title: "Premium",
            name: "premium",
            value: data?.keyStats_dealer?.premium,
          },
          {
            title: "Standard",
            name: "standard",
            value: data?.keyStats_dealer?.standard,
          },
          {
            title: "Basic",
            name: "basic",
            value: data?.keyStats_dealer?.basic,
          },
        ],
        additionalInfo: [
          {
            content: `${valid(
              data?.keyStats_dealer?.additionalInfo?.overallAds
            )} ads posted so far`,
            image: "akar-icons_info-fill.png",
          },
          {
            content: `${valid(dealer?.soldAds)} cars sold`,
            image: "akar-icons_info-fill.png",
          },
          {
            content: `${valid(dealer?.deletedAds)} cars delisted`,
            image: "akar-icons_info-fill.png",
          },
        ],
      },
      users: {
        stats: [
          {
            title: "Ultimate",
            name: "ultimate",
            value: data?.keyStats_user?.ultimate,
          },
          {
            title: "Premium",
            name: "premium",
            value: data?.keyStats_user?.premium,
          },
          {
            title: "Standard",
            name: "standard",
            value: data?.keyStats_user?.standard,
          },
          {
            title: "Basic",
            name: "basic",
            value: data?.keyStats_user?.basic,
          },
        ],
        additionalInfo: [
          {
            content: `${valid(
              data?.keyStats_user?.additionalInfo?.overallAds
            )} ads posted so far`,
            image: "akar-icons_info-fill.png",
          },
          {
            content: `${valid(user?.soldAds)} cars sold`,
            image: "akar-icons_info-fill.png",
          },
          {
            content: `${valid(user?.deletedAds)} cars delisted`,
            image: "akar-icons_info-fill.png",
          },
        ],
      },
      // total_car_sold: {
      //   title: " Cars sold so far (dealers  &  private users)",
      //   name: "totalSoldCars",
      //   value:
      //     data?.keyStats?.dealer?.additionalInfo?.soldAds +
      //     data?.keyStats?.users?.additionalInfo?.soldAds,
      // },
    },
    metrics: [
      {
        title: "Views on selling cars",
        name: "totalSellingViews",
        value: data?.metrics?.totalSellingViews,
      },
      {
        title: "Views on rental cars",
        name: "totalRentingViews",
        value: data?.metrics?.totalRentingViews,
      },
      {
        title: "Views on checking cars",
        name: "totalCheckCarViews",
        value: data?.metrics?.totalCheckCarViews,
      },
      {
        title: "Views on website",
        name: "websiteViews",
        value: data?.metrics?.websiteViews,
      },
      {
        title: "Views on app",
        name: "mobileAppViews",
        value: data?.metrics?.mobileAppViews,
      },
      //   {
      //     title: "Total views",
      //     name: "totalVisits",
      //     value: data?.metrics?.totalVisits,
      //   },
    ],
    graphs: {
      liveTrafficGraph: {
        title: "Live traffic",
        description: "Product trends by month",
        data: [10000, 15000, 20000, 15000, 50000, 30000, 40000],
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
      soldGraph: {
        title: "Selling rate",
        description: "Products sold by month",
        data: [800, 150, 200, 150, 200, 300, 900],
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
      rentedGraph: {
        title: "Renting rate",
        description: "Products rented by month",
        data: [400, 150, 200, 150, 300, 300, 400],
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
    },
  };
  return formattedData;
};
