import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { aprrovalAdvertColumn } from "../../../data/data";
import BasicTable from "../../Tools/Table";
import { getAdTypes, sendEmail, toggleAdDisplay } from "../../../services/api";
import SingleBoxItem from "../../Tools/SingleBoxItem";
import { ITEM_PER_PAGE } from "../../../data/varible";
import { apiManager } from "../../../data/apiHandler";
import BoostrapModel from "../../Tools/BoostrapModel";
import swal from "sweetalert";
import ReasonModel from "../../Tools/ReasonModel";
import { useDidMountEffect } from "../../../data/hooks";

const Adverts = () => {
  const localStorageKeys = {
    currPage: "currPageAllAds",
    tabUserType: "tabUserTypeAllAds",
    tabAdType: "tabAdTypeAllAds",
    link: "linksAllAds",
  };
  const tabs = [
    {
      value: "Brand",
      label: "Brand Ads",
    },
    {
      value: "Dealer",
      label: "Dealer Ads",
    },
    {
      value: "Private",
      label: "Private Ads",
    },
  ];
  const adTypes = [
    {
      value: "sell",
      label: "Sell",
    },
    {
      value: "rent",
      label: "Rent",
    },
  ];
  const links = [
    {
      value: "&display=ON",
      label: "Live ads",
      viewText: "live"
    },
    {
      value: "&isAdCompleted=YES&approveStatus=pending",
      label: "Pending ads",
      viewText: "pending"
    },
    {
      value: "&isAdCompleted=YES&approveStatus=rejected",
      label: "Rejected ads",
      viewText: "rejected"
    },
  ];
  //states
  const [submitting, setSubmitting] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [row, setRow] = useState([]);
  const [actions, setActions] = React.useState([]);
  const [userType, setUserType] = useState(
    localStorage.getItem(localStorageKeys.tabUserType) || ""
  );
  const [adType, setAdType] = useState(
    localStorage.getItem(localStorageKeys.tabAdType) || ""
  );
  const [selectedLink, setSelectedLink] = useState(
    links.filter(
      (v) => v?.value === localStorage.getItem(localStorageKeys.link)
    )?.[0] || {}
  );
  const [show, setShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    id: "",
    label: "",
    heading: "",
    buttonTitle: "",
    option: [],
    type: "",
    modalType: "",
    notes: [],
  });

  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [totalPages, setTotalPages] = useState("");
  //toogle modal states
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const openModal = (data) => {
    setModalData(data);
    setModal(true);
  };

  // Pagination state
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem(localStorageKeys.currPage)) || 1
  );

  const getUserType = (type) => {
    switch (type) {
      case "user":
        return "Private";
      case "dealer":
        return "Dealer";
      case "brand":
        return "Brand";
      default:
        return "Private";
    }
  };

  const limit = ITEM_PER_PAGE;
  const apiGetCalls = async () => {
    setSubmitting(true);
    const uType =
      userType === "Private"
        ? "user"
        : userType === "Dealer"
        ? "dealer"
        : userType === "Brand"
        ? "brand"
        : "";

    let adTypeId = "";
    const adData = await getAdTypes();

    if (adType == "sell") {
      adTypeId = adData.data.filter((v) => v.name.en === "Sell")[0]._id;
    }
    if (adType == "rent") {
      adTypeId = adData.data.filter((v) => v.name.en === "Rent")[0]._id;
    }

    apiManager(
      "GET",
      `opp/filteredAds?lang=en&limit=${limit}&page=${currentPage}&userType=${uType}&adType=${adTypeId}${
        selectedLink?.value || ""
      }`
    )
      .then((res) => {
        if (res.status) {
          let data = res?.data?.ads.map((item) => ({
            ...item,
            createdAt: moment.unix(item.adCreatedAt).format("DD/MM/YYYY"),
            item: `${item?.properties?.basicDetails?.make?.en} ${item?.properties?.basicDetails?.model?.en} ${item?.properties?.basicDetails?.makeYear}`,
            name:
              item?.owner?.dealerShipName &&
              item?.owner?.dealerShipName !== "N/A"
                ? item?.owner?.dealerShipName
                : item?.owner?.fullName && item?.owner?.fullName !== "N/A"
                ? item?.owner?.fullName
                : "--",
            email: item?.owner?.email,
            phoneNumber: item?.owner?.phoneNumber,
            address: item?.owner?.address,
            userType: getUserType(item?.owner?.userType),
            adType: item?.adTypeName?.en,
            rawData: item,
          }));
          setRow(data);
          setTotalCount(res?.data?.totalAds);
          setTotalPages(res?.data?.totalPages);
        } else {
          console.log(res);
          setRow([]);
          setTotalCount(0);
        }
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
      });
    fetchActionsAdvert();
  };

  useEffect(() => {
    apiGetCalls();
  }, [dispatch, currentPage]);

  useDidMountEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
    apiGetCalls();
  }, [userType, adType, selectedLink]);

  const fetchActionsAdvert = async () => {
    const viewNoraml = {
      label: "View",
      image: "",
      onclick: (id, data) => {
        // navigate(`/view_advert?id=${id}`);
        navigate(`${"/view_advert"}?id=${id}`);
      },
    };
    const edit = {
      label: "Edit Ad",
      image: "",
      onclick: (id, data) => {
        navigate(`/edit_advert?id=${id}`);
      },
    };
    const deleted = {
      label: "Delist Ad",
      image: "",
      onclick: (id, data) => {
        setState({
          id: id,
          title: "delisting advert",
          heading: "Delist advert",
          buttonTitle: "Submit",
          option: [],
          type: "note",
          modalType: "toggleAd",
          adData: data,
        });
        setShow(true);
      },
    };
    setActions([edit, deleted]);
  };

  const handleSubmit = (data) => {
    let formData = {};
    setLoading(true);
    switch (state?.modalType) {
      case "toggleAd":
        const toggleAdData = {
          // userId: state?.adData?.owner?._id,
          // display: state?.adData?.display ? "OFF" : "ON",
          // isAdImageUploaded: state?.adData?.display ? "" : "YES",
          active: "OFF",
          display: "OFF",
          deleted: "YES",
        };
        toggleAdDisplay(toggleAdData, state?.adData?._id).then((res) => {
          if (res.status) {
            formData = {
              subject: data?.title,
              message: data?.body,
              userIds: [state?.adData?.owner?._id],
              type: "0",
              attachments: selectedFile.map((v) => v?.s3URL),
            };
            sendEmail(formData).then((res) => {
              swal("Success", res?.message, "success");
              apiGetCalls();
              setShow(false);
            });
          } else {
            swal("Error", res?.message, "error");
          }
          setLoading(false);
        });
        break;
    }
  };

  return (
    <div class="overview">
      <div className="fs-3 mb-4 fw-bold">{userType || "All"} {selectedLink?.viewText || ""} adverts {adType ? `(${adType})` : ""}</div>
      <div className="row">
        <div className="mb-2 col-xl-6">
          <div
            class="btn-group shadow-sm my-tabs"
            style={{ borderRadius: "4px", height: "43px" }}
            role="group"
            aria-label="Basic example"
          >
            {tabs?.map((item) => {
              return (
                <button
                  disabled={submitting}
                  type="button"
                  class={`btn btn-light ${
                    userType == item?.value ? "activeButton" : ""
                  }`}
                  onClick={() => {
                    if (!submitting) {
                      if (userType === item?.value) {
                        setUserType("");
                        localStorage.setItem(localStorageKeys.tabUserType, "");
                      } else {
                        setUserType(item?.value);
                        localStorage.setItem(
                          localStorageKeys.tabUserType,
                          item?.value
                        );
                        // setData(tradeUsers);
                      }
                    }
                  }}
                >
                  {item?.label}
                </button>
              );
            })}
          </div>
        </div>
        <div className="mb-2 col-xl-6 d-flex justify-content-end">
          <div
            class="btn-group shadow-sm my-tabs w-75"
            style={{ borderRadius: "4px", height: "43px" }}
            role="group"
            aria-label="Basic example"
          >
            {adTypes.map((item) => {
              return (
                <button
                  disabled={submitting}
                  type="button"
                  class={`btn btn-light ${
                    adType == item?.value ? "activeButton" : ""
                  }`}
                  style={{ width: "150px" }}
                  onClick={() => {
                    if (adType === item?.value) {
                      setAdType("");
                      localStorage.setItem(localStorageKeys.tabAdType, "");
                      // setData(tradeUsers);
                    } else {
                      setAdType(item?.value);
                      localStorage.setItem(
                        localStorageKeys.tabAdType,
                        item?.value
                      );
                      // setData(tradeUsers);
                    }
                  }}
                >
                  {item?.label}
                </button>
              );
            })}
          </div>
        </div>
        <div className="d-flex justify-content-start flex-wrap gap-1 mt-2 mb-3">
          {links.map((item) => {
            return (
              <div
                disabled={submitting}
                type="button"
                className={`btn-link text-decoration-none shadow-sm rounded-1 px-2 ${
                  selectedLink?.value == item?.value ? "activeButton" : ""
                }`}
                // style={{ width: "150px" }}
                onClick={async () => {
                  if (!submitting) {
                    if (selectedLink?.value === item?.value) {
                      localStorage.setItem(localStorageKeys.link, "");
                      setSelectedLink("");
                    } else {
                      localStorage.setItem(localStorageKeys.link, item?.value);
                      setSelectedLink(item);
                    }
                  }
                }}
              >
                {item?.label}
              </div>
            );
          })}
        </div>
      </div>
      <BasicTable
        columns={aprrovalAdvertColumn ? aprrovalAdvertColumn : []}
        rows={row ? row : []}
        actions={actions}
        actionType={"dropdown"}
        dropDownImage={"../assets/images/dash_img/threedot.png"}
        // view={view}
        itemperpage={limit}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        submitting={submitting}
        onRowClick={(e) => navigate(`${"/view_advert"}?id=${e?._id}`)}
        totalPages={totalPages}
        localCurrPageKey={localStorageKeys.currPage}
      />
      <SingleBoxItem
        parentStyle={"indiv"}
        childStyle={"st"}
        heading={totalCount}
        description={"Total Results ( only active ads )"}
      />
      <BoostrapModel
        show={show}
        heading={state.heading}
        onHide={() => setShow(!show)}
        style={"w-100"}
        component={
          <ReasonModel
            id={state.id}
            label={state.title}
            buttonTitle={state.buttonTitle}
            option={state.option}
            type={state.type}
            data={state}
            setData={setState}
            handleSubmitEvent={handleSubmit}
            onHide={() => setShow(!show)}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            loading={loading}
          />
        }
      />
    </div>
  );
};
export default Adverts;
