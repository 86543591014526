import React, { useState } from "react";
import { checkVal, typeToLabel } from "../../../data/utils";
import { removeAccountFromDatabase } from "../../../services/api";
import swal from "sweetalert";
import { Icon } from "@iconify/react/dist/iconify.js";
function DeleteAccountPermanentally({ account, handleCloseModal, callback }) {
  const [deleteText, setDeleteText] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    setLoading(true);
    removeAccountFromDatabase(account?._id).then((res) => {
      if (res?.status) {
        handleCloseModal();
        callback();
        swal("Success", res?.message, "success");
      } else {
        swal("Error", res?.message, "error");
      }
      setLoading(false);
    });
  };
  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex justify-content-center">
        <img
          src={account?.profilePicture || "/assets/images/ap2.png"}
          alt=""
          className="w-100 rounded-2"
          height={200}
          style={{ objectFit: "contain" }}
        />
      </div>
      {checkVal(account?.userType) && (
        <h5
          className="fw-semibold d-flex justify-content-between"
          style={{ fontSize: "18px" }}
        >
          <span>Account type:</span>
          <span>{typeToLabel(account?.userType) || "--"}</span>
        </h5>
      )}
      {checkVal(account?.dealerShipName) && (
        <h5
          className="fw-semibold d-flex justify-content-between"
          style={{ fontSize: "18px" }}
        >
          <span>Name:</span>
          <span>{account?.fullName || "--"}</span>
        </h5>
      )}
      {checkVal(account?.dealerShipName) && (
        <h5
          className="fw-semibold d-flex justify-content-between"
          style={{ fontSize: "18px" }}
        >
          <span>Company name:</span>
          <span>{account?.dealerShipName || "--"}</span>
        </h5>
      )}
      {checkVal(account?.email) && (
        <h5
          className="fw-semibold d-flex justify-content-between"
          style={{ fontSize: "18px" }}
        >
          <span>Email:</span>
          <span>{account?.email || "--"}</span>
        </h5>
      )}
      {checkVal(account?.phoneNumber) && (
        <h5
          className="fw-semibold d-flex justify-content-between"
          style={{ fontSize: "18px" }}
        >
          <span>Phone number:</span>
          <span>{account?.phoneNumber || "--"}</span>
        </h5>
      )}
      <div>
        <h5
          className="text-danger fw-semibold d-flex justify-content-between"
          style={{ fontSize: "18px" }}
        >
          Type "DELETE" to confirm
        </h5>
        <input
          type="text"
          id="planPrice"
          className="form-control col-sm-6 mb-3 text-danger fw-bold"
          value={deleteText}
          onChange={(e) => setDeleteText(e.target.value)}
        />
        <div className="d-flex justify-content-end">
          <button
            className="themeButtonNo m-0"
            style={{ width: 180, fontSize: 15 }}
            onClick={() => handleSubmit()}
            disabled={loading || deleteText !== "DELETE"}
          >
            {loading ? (
              <Icon
                icon="line-md:loading-twotone-loop"
                width="24"
                height="24"
                style={{ color: "white" }}
              />
            ) : (
              "Proceed"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteAccountPermanentally;
