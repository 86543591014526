import React, { useState } from "react";
import { setContactAsVerified } from "../../../services/api";
import swal from "sweetalert";
import { Icon } from "@iconify/react/dist/iconify.js";
function VerifyContact({ account, refreshPage, handleCloseModal }) {
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    setLoading(true);
    setContactAsVerified(account?._id).then((res) => {
      if (res?.status) {
        handleCloseModal();
        refreshPage();
        swal("Success", res?.message, "success");
      } else {
        swal("Error", res?.message, "error");
      }
      setLoading(false);
    });
  };
  return (
    <div className="d-flex flex-column gap-3">
      <h5
        className="fw-semibold d-flex justify-content-between"
        style={{ fontSize: "18px" }}
      >
        <span>Email:</span>
        <span>{account?.email || "--"}</span>
      </h5>
      <h5
        className="fw-semibold d-flex justify-content-between"
        style={{ fontSize: "18px" }}
      >
        <span>Phone number:</span>
        <span>{account?.phoneNumber || "--"}</span>
      </h5>
      <div className="d-flex justify-content-end">
        <button
          className="themeButtonYes m-0"
          style={{ width: 180, fontSize: 15 }}
          onClick={() => handleSubmit()}
          disabled={loading}
        >
          {loading ? (
            <Icon
              icon="line-md:loading-twotone-loop"
              width="24"
              height="24"
              style={{ color: "white" }}
            />
          ) : (
            "Set contact as verified"
          )}
        </button>
      </div>
    </div>
  );
}

export default VerifyContact;
