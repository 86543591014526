import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { apiManager } from "../../../data/apiHandler";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  camelToNormal,
  convertDigitsToEnglish,
  fetchAdvertFormData,
  findUploads,
  translateInTwoLanguages,
} from "../../../data/utils";
import SelectCustomItems from "../../Tools/SelectCustomItems";
import SelectForm from "../../Tools/SelectForm";
import FormInputs from "../../Tools/FormInput/Newforminput";
import TextAreaForm from "../../Tools/TextAreaForm";
import { TagsInput } from "react-tag-input-component";
import ListItem from "../../Tools/ListItem";
import SellPrice from "../../Tools/SellPrice";
import RentPrice from "../../Tools/RentPrice";
import Media from "../../Tools/Media";
import Button from "../../Tools/Button";
import { getAdTypes, getModels, getVariants } from "../../../services/api";
import { useDidMountEffect } from "../../../data/hooks";
import ReactSelectForm from "../../Tools/ReactSelectForm";
import { hideSpec, isFieldRequired } from "./addFormUtils";

function EditAdvert() {
  // hooks
  const [params] = useSearchParams();
  const navigate = useNavigate();

  // states
  const [pageLoading, setPageLoading] = useState(false);
  const [page, setPage] = useState("SellYourCar");
  const [data, setData] = useState(null);
  const [selected, setSelected] = useState();
  const [selectedMake, setSelectedMake] = useState(null);
  const [models, setModels] = useState(null);
  const [trims, setTrims] = useState([]);
  const [uploadsInOrder, setUploadsInOrder] = useState([]);
  const [inputFile, setInputFile] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [InputFileError, setInputFileError] = useState();
  const [upload_list, set_upload_list] = useState({});
  const [initialPrefillDone, setInitialPrefillDone] = useState(false);

  const require = "This field is required.";
  const price = "Must be more than 1$";

  const schema = Yup.object().shape({
    make: Yup.string()
      .test("notChoose", require, (v) => v !== "Select")
      .required(require),
    model: Yup.object()
      .test("notChoose", require, (v) => v?.label !== "Select")
      .required(require),
    modelTrim: Yup.object(),
    makeYear: Yup.string()
      .test("notChoose", require, (v) => v !== "Select")
      .required(require),
    regional: !isFieldRequired("regional", page)
      ? Yup.string()
      : Yup.string()
          .test("notChoose", require, (v) => v !== "Select")
          .required(require),
    condition: !isFieldRequired("condition", page)
      ? Yup.string()
      : Yup.string()
          .test("notChoose", require, (v) => v !== "Select")
          .required(require),
    colour: Yup.string()
      .test("notChoose", require, (v) => v !== "Select")
      .required(require),
    interiorColor: Yup.string()
      .test("notChoose", require, (v) => v !== "Select")
      .required(require),
    paint: !isFieldRequired("paint", page)
      ? Yup.string()
      : Yup.string()
          .test("notChoose", require, (v) => v !== "Select")
          .required(require),
    transmission: Yup.string()
      .test("notChoose", require, (v) => v !== "Select")
      .required(require),
    fuel: Yup.string()
      .test("notChoose", require, (v) => v !== "Select")
      .required(require),
    body: Yup.string()
      .test("notChoose", require, (v) => v !== "Select")
      .required(require),
    kilometers: !isFieldRequired("kilometers", page)
      ? Yup.string()
      : Yup.string().required(require),
    size: Yup.string(),
    power: Yup.string(),
    cylinders: Yup.string(),

    batterySize: Yup.string(),

    doors: Yup.string()
      .test("notChoose", require, (v) => v !== "Select")
      .required(require),
    seats: Yup.string()
      .test("notChoose", require, (v) => v !== "Select")
      .required(require),
    brandNew: !isFieldRequired("brandNew", page)
      ? Yup.string()
      : Yup.string()
          .test("notChoose", require, (v) => v !== "Select")
          .required(require),
    feature: Yup.array().of(Yup.string().required(require)),
    description: Yup.string(),
    city: Yup.string(),
    // .required(require)
    // .test("notChoose", require, (v) => v !== t("Select")),
    cityPlate: Yup.string(),
    registrationNumber: Yup.string(),
    plateType: Yup.string(),
    dateOfRegistration: Yup.string(),
    finance: Yup.string(),
    adLabel: Yup.string(),

    dollorPrice: Yup.string(),
    dollorDaily: Yup.string(),
    dollorWeekly: Yup.string(),
    dollorMonthly: Yup.string(),

    asking_price:
      page == "RentYourCar" ? Yup.string() : Yup.string().required(require),
    // .test("minPrice", price, (v) => parseInt(v) > 1),
    daily_price:
      page !== "RentYourCar" ? Yup.string() : Yup.string().required(require),
    // .test("minPrice", price, (v) => parseInt(v) > 1),
    weekly_price:
      page !== "RentYourCar" ? Yup.string() : Yup.string().required(require),
    // .test("minPrice", price, (v) => parseInt(v) > 1),
    monthly_price:
      page !== "RentYourCar" ? Yup.string() : Yup.string().required(require),
    // .test("minPrice", price, (v) => parseInt(v) > 1),
  });

  const {
    control,
    register,
    getValues,
    trigger,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      isTrimCustom: false,
    },
  });

  const getAdvertData = async (id) => {
    setPageLoading(true);
    const allApiData = await Promise.all([
      fetchAdvertFormData(),
      apiManager("GET", `opp/filteredAds?adId=${id}`),
    ]);
    set_upload_list(allApiData[0].data);
    const dataT = allApiData[1]?.data?.ads?.[0];
    const sellRent =
      dataT?.adTypeName?.en === "Sell" ? "SellYourCar" : "RentYourCar";
    setPage(sellRent);
    prefillForm(dataT, sellRent);

    setData(() => dataT);
    setSelected(
      dataT?.properties?.features?.anyThingElse
        ? dataT?.properties?.features?.anyThingElse?.split(",")
        : []
    );
    const entityUploads =
      dataT?.uploads && dataT?.uploads?.length > 0
        ? dataT?.uploads.map((v) => v?.s3URL)
        : [];

    const all_list = findUploads(dataT);
    setUploadsInOrder(all_list?.map((v, i) => ({ ...v, id: i + 1 })));

    setUploadedFiles(entityUploads);
    setSelectedMake(
      allApiData[0].data?.make?.filter((v) => {
        return (
          v?.name?.toLowerCase?.() ==
          dataT?.properties?.basicDetails?.make?.["en"]?.toLowerCase()
        );
      })?.[0]
    );

    setPageLoading(false);
  };

  useEffect(() => {
    const adId = params.get("id");
    if (adId) {
      getAdvertData(adId);
    }
  }, [params]);

  const prefillForm = async (data, page) => {
    setValue("make", data?.makeName ? data?.makeName : "Select");
    setValue(
      "model",
      data?.properties?.basicDetails?.model?.["en"]
        ? {
            label: data?.properties?.basicDetails?.model?.["en"],
            value: data?.properties?.basicDetails?.model?.["en"],
          }
        : { label: "Select", value: "Select" }
    );
    setValue(
      "modelTrim",
      data?.properties?.basicDetails?.modelTrim?.["en"]
        ? {
            label: data?.properties?.basicDetails?.modelTrim?.["en"],
            value: data?.properties?.basicDetails?.modelTrim?.["en"],
          }
        : { label: "Select", value: "Select" }
    );
    setValue(
      "makeYear",
      data?.properties?.basicDetails?.makeYear
        ? data?.properties?.basicDetails?.makeYear
        : "Select"
    );
    setValue(
      "regional",
      data?.properties?.basicDetails?.regionalSpecs?.["en"]
        ? data?.properties?.basicDetails?.regionalSpecs?.["en"]
        : "Select"
    );
    setValue(
      "condition",
      data?.properties?.basicDetails?.condition?.["en"]
        ? data?.properties?.basicDetails?.condition?.["en"]
        : "Select"
    );
    setValue(
      "colour",
      data?.properties?.basicDetails?.color
        ? data?.properties?.basicDetails?.color
        : "Select"
    );
    setValue(
      "interiorColor",
      data?.properties?.basicDetails?.interiorColor
        ? data?.properties?.basicDetails?.interiorColor
        : "Select"
    );
    setValue(
      "paint",
      data?.properties?.specifications?.paint?.["en"]
        ? data?.properties?.specifications?.paint?.["en"]
        : "Select"
    );
    setValue(
      "transmission",
      data?.properties?.specifications?.gearbox?.["en"]
        ? data?.properties?.specifications?.gearbox?.["en"]
        : "Select"
    );
    setValue(
      "fuel",
      data?.properties?.specifications?.fuelType?.["en"]
        ? data?.properties?.specifications?.fuelType?.["en"]
        : "Select"
    );
    setValue(
      "body",
      data?.properties?.specifications?.bodyType?.["en"]
        ? data?.properties?.specifications?.bodyType?.["en"]
        : "Select"
    );
    setValue(
      "kilometers",
      data?.properties?.specifications?.km == 0
        ? "0"
        : data?.properties?.specifications?.km
        ? data?.properties?.specifications?.km
        : ""
    );
    setValue(
      "size",
      data?.properties?.specifications?.engineSize
        ? upload_list?.size?.filter(
            (v) => v.value == data?.properties?.specifications?.engineSize
          )?.[0]?.name?.["en"]
        : "Select"
    );
    setValue(
      "power",
      data?.properties?.specifications?.enginePower
        ? data?.properties?.specifications?.enginePower
        : ""
    );
    setValue(
      "cylinders",
      data?.properties?.specifications?.cylinders
        ? data?.properties?.specifications?.cylinders
        : "Select"
    );
    setValue(
      "doors",
      data?.properties?.specifications?.doors
        ? data?.properties?.specifications?.doors
        : "Select"
    );
    setValue(
      "seats",
      data?.properties?.specifications?.seats
        ? data?.properties?.specifications?.seats
        : "Select"
    );
    // setValue(
    //   "feature",
    //   data?.properties?.features?.anyThingElse
    //     ? t(data?.properties?.features?.anyThingElse?.split(","))
    //     : []
    // );
    setValue(
      "description",
      data?.properties?.features?.carDescription?.["en"]
        ? data?.properties?.features?.carDescription?.["en"]
        : ""
    );
    setValue("city", data?.city || "");
    setValue(
      "cityPlate",
      data?.properties?.cityPlate
        ? data?.properties?.cityPlate?.["en"] || data?.properties?.cityPlate
        : "Select"
    );
    setValue(
      "registrationNumber",
      data?.properties?.registrationNumber
        ? data?.properties?.registrationNumber
        : ""
    );
    setValue(
      "finance",
      data?.properties?.finance ? data?.properties?.finance : "Select"
    );
    setValue(
      "adLabel",
      data?.properties?.adLabel
        ? data?.properties?.adLabel === "Brand new label"
          ? "Brand new"
          : data?.properties?.adLabel
        : "Select"
    );
    setValue(
      "plateType",
      data?.properties?.plateType
        ? data?.properties?.plateType.startsWith("Commercial")
          ? "Commercial"
          : data?.properties?.plateType
        : "Select"
    );
    setValue(
      "brandNew",
      data?.brandNew == true ? "Yes" : data?.brandNew == false ? "No" : "Select"
    );

    if (page === "RentYourCar") {
      setValue(
        "daily_price",
        data?.askPrice?.toString() == "0" ? "0" : data?.askPrice?.toString()
      );
      setValue(
        "dollorDaily",
        data?.dollorAskPrice?.toString() == "0"
          ? "0"
          : data?.dollorAskPrice?.toString()
      );
      setValue("asking_price", "");
      setValue("dollorPrice", "");

      setValue(
        "weekly_price",
        data?.askPrice1?.toString() == "0" ? "0" : data?.askPrice1?.toString()
      );
      setValue(
        "monthly_price",
        data?.askPrice2?.toString() == "0" ? "0" : data?.askPrice2?.toString()
      );
      setValue(
        "dollorWeekly",
        data?.dollorAskPrice1?.toString() == "0"
          ? "0"
          : data?.dollorAskPrice1?.toString()
      );
      setValue(
        "dollorMonthly",
        data?.dollorAskPrice2?.toString() == "0"
          ? "0"
          : data?.dollorAskPrice2?.toString()
      );
    } else {
      setValue(
        "asking_price",
        data?.askPrice?.toString() == "0" ? "0" : data?.askPrice?.toString()
      );
      setValue(
        "dollorPrice",
        data?.dollorAskPrice?.toString() == "0"
          ? "0"
          : data?.dollorAskPrice?.toString()
      );
      setValue("daily_price", "");
      setValue("dollorDaily", "");

      setValue("weekly_price", "");
      setValue("monthly_price", "");
      setValue("dollorWeekly", "");
      setValue("dollorMonthly", "");
    }

    setValue(
      "make",
      data?.properties?.basicDetails?.make?.["en"]
        ? data?.properties?.basicDetails?.make?.["en"]
        : "Select"
    );

    setInitialPrefillDone(true);
  };

  // useEffect(() => {
  //   if (data) prefillForm(data);
  // }, [data]);

  useEffect(() => {
    if (selectedMake?.name) {
      setModels([]);
      getModels({ makeId: selectedMake?.name }).then((res) => {
        const hasModel = res?.includes(
          data?.properties?.basicDetails?.model?.["en"]
        );
        res = res?.map((v) => ({ label: v, value: v }));
        setModels(res);
        if (hasModel) {
          setValue(
            "model",
            data?.properties?.basicDetails?.model?.["en"]
              ? {
                  label: data?.properties?.basicDetails?.model?.["en"],
                  value: data?.properties?.basicDetails?.model?.["en"],
                }
              : { label: "Select", value: "Select" }
          );
        } else {
          setValue("model", { label: "Select", value: "Select" });
        }
      });
    }
  }, [selectedMake]);

  useDidMountEffect(() => {
    if (selectedMake?.name && watch("model")) {
      setTrims(null);
      getVariants({
        make: selectedMake?.name,
        model: watch("model")?.label,
      }).then((res) => {
        const hasTrim = res?.includes(
          data?.properties?.basicDetails?.modelTrim?.["en"]
        );
        res = res?.map((v) => ({ label: v, value: v }));
        setTrims(res);

        // if (hasTrim) {
        setValue(
          "modelTrim",
          data?.properties?.basicDetails?.modelTrim?.["en"]
            ? {
                label: data?.properties?.basicDetails?.modelTrim?.["en"],
                value: data?.properties?.basicDetails?.modelTrim?.["en"],
              }
            : { label: "Select", value: "Select" }
        );
        // } else {
        // setValue("modelTrim", { label: t("Select"), value: t("Select") });
        // }
      });
    }
  }, [watch("model")]);

  const getMultilingualObject = (objectName, value) => {
    const objData = upload_list[objectName];
    // const value = t(val, { lng: 'English', returnObjects })

    const selectedObject = objData?.filter(({ name }) => {
      return (
        name["en"] === value ||
        name["English"] === value ||
        name["ar"] === value ||
        name["Arabic"] === value ||
        name["ku"] === value ||
        name["Kurdish"] === value
      );
    })[0];
    return selectedObject?.name;
  };
  const onSubmit = async (data) => {
    // data.make = camelToNormal(data?.make?.toLowerCase());

    if (!uploadsInOrder?.length) {
      setInputFileError("Files are required");
      return;
    }

    if (uploadsInOrder.length < 4) {
      setInputFileError("Please upload minimum four images");
      return;
    }

    setPageLoading(true);

    // if (!back) setInputFileError("");

    console.log(data);

    const allLangDescriptions = await translateInTwoLanguages(
      "en",
      data.description
    );
    const bodyTypeData = upload_list.body;
    const selectedBodyType = bodyTypeData.filter(
      ({ name }) => name?.["en"] === data?.body
    )[0];
    console.log(bodyTypeData);
    console.log(selectedBodyType);

    const selectedModel = models?.filter(
      (v) => v.value?.toLowerCase() === data.model.label?.toLowerCase()
    )?.[0];

    const makeInAllLang = {
      en: selectedMake?.name,
      ar: selectedMake?.name,
      ku: selectedMake?.name,
    };

    const modelInAllLang = {
      en: selectedModel?.label,
      ar: selectedModel?.label,
      ku: selectedModel?.label,
    };

    const adTypes = await getAdTypes();

    const ids = {
      categoryId: selectedBodyType?.categoryId,
      subcategoryId: selectedBodyType?.subcategoryId,
      entityTypeId: selectedBodyType?.id,
      adTypeId:
        page === "RentYourCar"
          ? adTypes?.data?.[1]?._id
          : adTypes?.data?.[0]?._id,
      makeId: selectedMake?._id,
    };
    const features = {
      fabricSeats: data.fabricSeats,
      leatherSeats: data.leatherSeats,
      mix: data.mix,
      rearCam: data.rearCam,
      electricTailgate: data.electricTailgate,
      frontCam: data.frontCam,
      cam360: data.cam360,
      sunroof: data.sunroof,
      blindSpot: data.blindSpot,
      ledHeadlights: data.ledHeadlights,
      remoteStart: data.remoteStart,
      airSuspensionSystem: data.airSuspensionSystem,
      headUpDisplay: data.headUpDisplay,
      abs: data.abs,
      airbags: data.airbags,
      ac: data.ac,
      acSeats: data.acSeats,
      xUsb: data.xUsb,
      bluetooth: data.bluetooth,
      coolingFridge: data.coolingFridge,
      cruiseControl: data.cruiseControl,
      electricDoors: data.electricDoors,
      electricMirrors: data.electricMirrors,
      electricSeats: data.electricSeats,
      dashboardProjector: data.dashboardProjector,
      heatedSeats: data.heatedSeats,
      heatedSteringWheel: data.heatedSteringWheel,
      keylessEntry: data.keylessEntry,
      ledLights: data.ledLights,
      massgeSeats: data.massgeSeats,
      navigationSystem: data.navigationSystem,
      parkingSensors: data.parkingSensors,
      powerLocks: data.powerLocks,
      smartEntry: data.smartEntry,
      smartParking: data.smartParking,
      touchscreen: data.touchscreen,
      usbTypeC: data.usbTypeC,
      wirelessCharging: data.wirelessCharging,
      xenonLights: data.xenonLights,
      anyThingElse: selected.join(", "),
      carDescription: allLangDescriptions,
    };

    let tags = [];
    Object.keys(features).forEach((key) => {
      if (data?.properties?.features[key] && key !== "anyThingElse") {
        tags.push(camelToNormal(key));
      }
    });
    tags = [...tags, ...selected];
    let fullData = {
      ...ids,
      makeName: selectedMake?.name,
      adTitle: {
        en: selectedMake?.name,
        ar: selectedMake?.name,
        ku: selectedMake?.name,
      },
      adDescription: {
        en: "AdDescription",
        ar: "AdDescription",
        ku: "AdDescription",
      },
      city: data?.city,
      country: {
        en: "Iraq",
        ar: "العراق",
        ku: "عێراق",
      },
      latitude: "32",
      longitude: "43",

      askPrice:
        page == "RentYourCar"
          ? parseInt(convertDigitsToEnglish(data?.daily_price))
          : parseInt(convertDigitsToEnglish(data?.asking_price)),
      askPrice1: data?.weekly_price
        ? parseInt(convertDigitsToEnglish(data?.weekly_price))
        : "",
      askPrice2: data?.monthly_price
        ? parseInt(convertDigitsToEnglish(data?.monthly_price))
        : "",

      dollorAskPrice:
        page == "RentYourCar"
          ? data?.dollorDaily
            ? parseInt(convertDigitsToEnglish(data?.dollorDaily))
            : "0"
          : data?.dollorPrice
          ? parseInt(convertDigitsToEnglish(data?.dollorPrice))
          : "0",
      dollorAskPrice1: data?.dollorWeekly
        ? parseInt(convertDigitsToEnglish(data?.dollorWeekly))
        : "0",
      dollorAskPrice2: data?.dollorMonthly
        ? parseInt(convertDigitsToEnglish(data?.dollorMonthly))
        : "0",

      currency: "IQD",
      brandNew: data?.brandNew === "Yes" ? "ON" : "OFF",
      tags: tags,

      description: {
        en: allLangDescriptions["en"],
        ar: allLangDescriptions["ar"],
        ku: allLangDescriptions["ku"],
      },

      isAdImageUploaded: uploadsInOrder.length > 0 ? "YES" : "NO",

      properties: {
        basicDetails: {
          make: makeInAllLang,
          makeYear: parseInt(data.makeYear),
          model: modelInAllLang,
          modelTrim:
            data?.modelTrim?.label !== "Select"
              ? {
                  en: data?.modelTrim?.label,
                  ar: data?.modelTrim?.label,
                  ku: data?.modelTrim?.label,
                }
              : { en: "", ar: "", ku: "" },
          regionalSpecs: data?.regional
            ? getMultilingualObject("regional", data?.regional)
            : "",
          condition: data?.condition
            ? getMultilingualObject("condition", data.condition)
            : "",
          color: getMultilingualObject("colour", data?.colour)?.["en"],
          interiorColor: getMultilingualObject(
            "interiorColor",
            data?.interiorColor
          )?.["en"],
        },
        specifications: {
          paint: data?.paint ? getMultilingualObject("paint", data.paint) : "",
          km: data?.kilometers
            ? parseInt(convertDigitsToEnglish(data.kilometers))
            : "",
          gearbox: getMultilingualObject("transmission", data.transmission),
          fuelType: getMultilingualObject("fuel", data.fuel),
          bodyType: getMultilingualObject("body", data.body),
          engineSize: upload_list["size"]?.filter(
            (v) => v.name?.["en"] === data.size
          )[0]?.value,
          // engineSize:  parseFloat(parseFloat(convertDigitsToEnglish(data.size)).toFixed(1)),
          // enginePower: parseInt(upload_list["power"]?.filter(
          //   (v) => v.name?.["en"] === data.power
          // )[0]?.value),
          enginePower: parseInt(convertDigitsToEnglish(data.power)),
          batterySize: parseInt(convertDigitsToEnglish(data.batterySize)),
          cylinders: parseInt(data.cylinders),
          doors: parseInt(data.doors),
          seats: parseInt(data.seats),
        },
        features: {
          ...features,
          carDescription: {
            en: allLangDescriptions["en"],
            ar: allLangDescriptions["ar"],
            ku: allLangDescriptions["ku"],
          },
        },
        manualTrim: data?.modelTrim?.["__isNew__"] ? "true" : "false",
        price:
          page == "RentYourCar"
            ? {
                daily: parseInt(convertDigitsToEnglish(data.daily_price)),
                weekly: parseInt(convertDigitsToEnglish(data.weekly_price)),
                monthly: parseInt(convertDigitsToEnglish(data.monthly_price)),
              }
            : parseInt(convertDigitsToEnglish(data.asking_price)),

        finance: data.finance,
        // city: city,
        registrationNumber: data.registrationNumber,
        plateType: data?.plateType
          ? data.plateType === "Select"
            ? ""
            : upload_list.plateTypes.filter(
                (v) => v?.name?.en === data.plateType
              )?.[0]?.value
          : "",
        // dateOfRegistration: moment(data?.dateOfRegistration).unix(),
        brandNew: data?.brandNew === "Yes" ? "Yes" : "No",
        adLabel:
          data?.adLabel !== "Select"
            ? data?.adLabel === "Brand new"
              ? "Brand new label"
              : data?.adLabel
            : "",
        cityPlate: data?.cityPlate !== "Select" ? data?.cityPlate : "",
      },

      uploadsInOrder: {
        uploads: uploadsInOrder.map(({ id, ...v }) => v),
      },
    };

    // if(page === "SellYourCar") {
    //   fullData = {
    //     ...fullData,
    //     askPrice1: 0
    //   }
    // }

    if (page === "RentYourCar") delete fullData.properties.finance;

    console.log(fullData);
    apiManager("PUT", `opp/ad?lang=${"en"}&adId=${params.get("id")}`, fullData)
      .then((res) => {
        if (res.status) {
          navigate(-1);
          setPageLoading?.(false);
        } else {
          console.log(res, "error");
        }
        setPageLoading(false);
      })
      .catch((err) => {
        setPageLoading(false);
        console.log(err, "error");
      });
  };

  const onError = (data) => {
    console.log("error", data);
  };

  //Price Conversion (Dollor to IQD)

  const exchangeRate = JSON.parse(
    localStorage.getItem("global")
  )?.exchangeRateDollarToIqd;

  useDidMountEffect(() => {
    if (initialPrefillDone) {
      let dollorPrice = watch("dollorPrice");
      dollorPrice = dollorPrice == 0 ? "" : dollorPrice;
      if (exchangeRate) {
        if (dollorPrice) {
          setValue(
            "asking_price",
            parseInt(dollorPrice * exchangeRate).toString()
          );
        } else {
          setValue("asking_price", "");
        }
      }
    }
  }, [watch("dollorPrice")]);

  useDidMountEffect(() => {
    if (initialPrefillDone) {
      let dollorPrice = watch("dollorDaily");
      dollorPrice = dollorPrice == 0 ? "" : dollorPrice;
      if (exchangeRate) {
        if (dollorPrice) {
          setValue(
            "daily_price",
            parseInt(dollorPrice * exchangeRate).toString()
          );
        } else {
          setValue("daily_price", "");
        }
      }
    }
  }, [watch("dollorDaily")]);

  useDidMountEffect(() => {
    if (initialPrefillDone) {
      let dollorPrice = watch("dollorWeekly");
      dollorPrice = dollorPrice == 0 ? "" : dollorPrice;
      if (exchangeRate) {
        if (dollorPrice) {
          setValue(
            "weekly_price",
            parseInt(dollorPrice * exchangeRate).toString()
          );
        } else {
          setValue("weekly_price", "");
        }
      }
    }
  }, [watch("dollorWeekly")]);

  useDidMountEffect(() => {
    if (initialPrefillDone) {
      let dollorPrice = watch("dollorMonthly");
      dollorPrice = dollorPrice == 0 ? "" : dollorPrice;
      if (exchangeRate) {
        if (dollorPrice) {
          setValue(
            "monthly_price",
            parseInt(dollorPrice * exchangeRate).toString()
          );
        } else {
          setValue("monthly_price", "");
        }
      }
    }
  }, [watch("dollorMonthly")]);

  if (pageLoading) {
    return (
      <div className="min-vh-100 d-flex justify-content-center mt-5 pt-5">
        <div className="d-flex justify-content-center">
          <div class="spinner-grow" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="min-vh-100 sell_your">
      <div
        role="button"
        onClick={() => navigate(-1)}
        className="d-flex align-items-center"
      >
        <img src={"../assets/images/backbt.png"} height={28} />
        <span className="fw-semibold mx-2">Back</span>
      </div>

      <div className="d-flex align-items-center">
        <h3>Edit advert</h3>
      </div>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div class="other_div other_div2">
          <h3>{"Basic Details"}</h3>
          <div class="row">
            <div class="col-sm-6 col-lg-4">
              <div class="form-group">
                <label>{"Make"}*</label>
                {/* <SelectForm
                  style={"form-control"}
                  selectedOption={data?.properties?.basicDetails?.make?.["en"]}
                  event={register}
                  name={"make"}
                  values={upload_list?.make}
                /> */}
                <SelectCustomItems
                  selectedOption={selectedMake}
                  // items1={upload_list?.make?.slice(0, 10)}
                  items2={upload_list?.make}
                  // title1={"Most searched for"}
                  title2={"All makes"}
                  placeholder={"Select"}
                  handelChange={(e) => {
                    setValue("make", e?.value);
                    setSelectedMake(e?.item);
                  }}
                  showDropdownIcon
                  width={"100%"}
                  hasSelected={data?.properties?.basicDetails?.make?.["en"]}
                />
                {errors.make && getValues("make") === "Select" && (
                  <p>{errors.make.message}</p>
                )}
                {/* <DynamicSelectComponent
                  name={"make"}
                  placeholder={("Select")}
                  setValue={setValue}
                  optionsBuilt={upload_list.make}
                />
                {errors.make && <p>{errors.make.message}</p>} */}
              </div>
            </div>
            <div class="col-sm-6 col-lg-4">
              <div class="form-group">
                <label>{"Model"}*</label>
                {/* <SelectForm
                  style={"form-control"}
                  // selectedOption={data?.properties?.basicDetails?.model?.["en"]}
                  event={register}
                  name={"model"}
                  values={models}
                  disabled={!models || models?.length == 0}
                /> */}
                <Controller
                  control={control}
                  name="model"
                  render={({ field }) => {
                    return (
                      <ReactSelectForm
                        value={field.value}
                        // selectedOption={watch("model")}
                        items={models}
                        onChange={(op) => field.onChange(op)}
                        showDropdownIcon
                        width={"100%"}
                        disabled={!models || models?.length == 0}
                      />
                    );
                  }}
                />
                {errors.model && <p>{errors.model.message}</p>}
              </div>
            </div>
            <div class="col-sm-6 col-lg-4">
              <div class="form-group">
                <label>{"Model variant"}</label>
                {/* <SelectForm
              style={"form-control"}
            event={register}
              name={"model"}
              values={models}
              disabled={!models || models?.length == 0}
            /> */}
                <Controller
                  control={control}
                  name="modelTrim"
                  render={({ field }) => {
                    return (
                      <ReactSelectForm
                        canCreate
                        value={field.value}
                        // selectedOption={watch("model")}
                        items={trims}
                        onChange={(op) => field.onChange(op)}
                        showDropdownIcon
                        width={"100%"}
                        // disabled={!trims || trims?.length == 0}
                      />
                    );
                  }}
                />

                {/* <DynamicSelectComponent
                  name={"model"}
                  placeholder={t("Select")}
                  setValue={setValue}
                  optionsBuilt={models}
                  disabled={!hasMake()}
                /> */}
                {errors.modelTrim && <p>{errors.modelTrim.message}</p>}
              </div>
            </div>
            <div class="col-sm-6 col-lg-4">
              <div class="form-group">
                <label>{"Year"}*</label>
                {/* <FormInput
                  style={"form-control"}
                  intype={"number"}
                  value={data?.properties?.basicDetails?.makeYear}
                  event={register}
                  name={"makeYear"}
                  placeholder={("Select year")}
                /> */}
                <SelectForm
                  style={"form-control"}
                  selectedOption={parseInt(
                    data?.properties?.basicDetails?.makeYear
                  )}
                  event={register}
                  name={"makeYear"}
                  values={upload_list.yearsList ? upload_list.yearsList : []}
                />
                {errors?.makeYear && <p>{errors?.makeYear.message}</p>}
              </div>
            </div>
            {isFieldRequired("regional", page) && (
              <div class="col-sm-6 col-lg-4">
                <div class="form-group">
                  <label>{"Regional Specs"}*</label>
                  <SelectForm
                    style={"form-control"}
                    selectedOption={
                      data?.properties?.basicDetails?.regionalSpecs?.["en"]
                    }
                    event={register}
                    name={"regional"}
                    values={upload_list.regional ? upload_list.regional : []}
                  />
                  {errors.regional && <p>{errors.regional.message}</p>}
                </div>
              </div>
            )}
            {isFieldRequired("condition", page) && (
              <div class="col-sm-6 col-lg-4">
                <div class="form-group">
                  <label>{"Body Condition"}*</label>
                  <SelectForm
                    style={"form-control"}
                    selectedOption={
                      data?.properties?.basicDetails?.condition?.["en"]
                    }
                    event={register}
                    name={"condition"}
                    values={upload_list.condition ? upload_list.condition : []}
                  />
                  {errors.condition && <p>{errors.condition.message}</p>}
                </div>
              </div>
            )}
            <div class="col-sm-6 col-lg-4">
              <div class="form-group">
                <label>{"Exterior colour"}*</label>
                <SelectForm
                  type={"color"}
                  style={"form-control"}
                  selectedOption={data?.properties?.basicDetails?.color?.["en"]}
                  event={register}
                  name={"colour"}
                  values={upload_list.colour ? upload_list.colour : []}
                />
                {/* <input
                  type="color"
                  placeholder={("Select Colour")}
                  onChange={(e) => setValue("colour", e.target.value)}
                  className={"form-control"}
                  defaultValue={data?.properties?.basicDetails?.color}
                /> */}
                {errors.colour && <p>{errors.colour.message}</p>}
              </div>
              {/* <div className="col-1"></div>
              <div className="col-3 border rounded-2" style={{marginTop: '44px', backgroundColor: showColor}}></div> */}
            </div>
            <div class="col-sm-6 col-lg-4">
              <div class="form-group">
                <label>{"Interior colour"}*</label>
                <SelectForm
                  type={"color"}
                  style={"form-control"}
                  selectedOption={data?.properties?.basicDetails?.interiorColor}
                  event={register}
                  name={"interiorColor"}
                  values={
                    upload_list.interiorColor ? upload_list.interiorColor : []
                  }
                />
                {/* <input
                  type="color"
                  placeholder={t("Select Colour")}
                  onChange={(e) => setValue("colour", e.target.value)}
                  className={"form-control"}
                  defaultValue={data?.properties?.basicDetails?.color}
                /> */}
                {errors.interiorColor && <p>{errors.interiorColor.message}</p>}
              </div>
              {/* <div className="col-1"></div>
              <div className="col-3 border rounded-2" style={{marginTop: '44px', backgroundColor: showColor}}></div> */}
            </div>
          </div>
        </div>

        <div class="other_div other_div2">
          <div className="row d-flex justify-content-between">
            <div className="col-7">
              <h3>{"Specifications"}</h3>
            </div>
          </div>
          <div class="row">
            {isFieldRequired("paint", page) && (
              <div class="col-sm-6 col-lg-4">
                <div class="form-group">
                  <label>{"Paint"}*</label>
                  <SelectForm
                    style={"form-control"}
                    selectedOption={
                      data?.properties?.specifications?.paint?.["en"]
                    }
                    event={register}
                    name={"paint"}
                    values={upload_list.paint ? upload_list.paint : []}
                  />
                  {errors.paint && <p>{errors.paint.message}</p>}
                </div>
              </div>
            )}
            {isFieldRequired("kilometers", page) && (
              <div class="col-sm-6 col-lg-4">
                <div class="form-group">
                  <label>{"Kilometers"}*</label>
                  <FormInputs
                    style={"form-control"}
                    intype={"text"}
                    value={data?.properties?.specifications?.km}
                    event={register}
                    placeholder={"Select Kilometres"}
                    name={"kilometers"}
                  />
                  {errors.kilometers && <p>{errors.kilometers.message}</p>}
                </div>
              </div>
            )}
            <div class="col-sm-6 col-lg-4">
              <div class="form-group">
                <label>{"Transmission"}*</label>
                <SelectForm
                  style={"form-control"}
                  selectedOption={
                    data?.properties?.specifications?.gearbox?.["en"]
                  }
                  event={register}
                  name={"transmission"}
                  values={
                    upload_list.transmission ? upload_list.transmission : []
                  }
                />
                {errors.transmission && <p>{errors.transmission.message}</p>}
              </div>
            </div>
            <div class="col-sm-6 col-lg-4">
              <div class="form-group">
                <label>{"Fuel Type"}*</label>
                <SelectForm
                  style={"form-control"}
                  selectedOption={
                    data?.properties?.specifications?.fuelType?.["en"]
                  }
                  event={register}
                  name={"fuel"}
                  values={upload_list.fuel ? upload_list.fuel : []}
                />
                {errors.fuel && <p>{errors.fuel.message}</p>}
              </div>
            </div>
            <div class="col-sm-6 col-lg-4">
              <div class="form-group">
                <label> {"Body Type"}*</label>
                <SelectForm
                  style={"form-control"}
                  selectedOption={
                    data?.properties?.specifications?.bodyType?.["en"]
                  }
                  event={register}
                  name={"body"}
                  values={upload_list.body ? upload_list.body : []}
                />
                {errors.body && <p>{errors.body.message}</p>}
              </div>
            </div>
            {!hideSpec?.engineSize?.includes(watch("fuel")) ? (
              <div class="col-sm-6 col-lg-4">
                <div class="form-group">
                  <label>{"Engine Size"}</label>
                  <SelectForm
                    style={"form-control"}
                    selectedOption={
                      upload_list?.size?.filter(
                        (v) =>
                          v.value ==
                          data?.properties?.specifications?.engineSize
                      )?.[0]?.name?.["en"]
                      // data?.properties?.specifications?.engineSize?.[lang2]
                    }
                    event={register}
                    name={"size"}
                    values={upload_list.size ? upload_list.size : []}
                  />
                  {errors.size && <p>{errors.size.message}</p>}
                </div>
              </div>
            ) : (
              <></>
            )}
            {!hideSpec?.batterySize?.includes(watch("fuel")) ? (
              <div class="col-sm-6 col-lg-4">
                <div class="form-group">
                  <label>{"Battery size (kWh)"}</label>
                  <FormInputs
                    style={"form-control"}
                    intype={"text"}
                    value={data?.properties?.specifications?.batterySize}
                    event={register}
                    placeholder={"Enter battery size"}
                    name={"batterySize"}
                  />
                  {errors.batterySize && <p>{errors.batterySize.message}</p>}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div class="col-sm-6 col-lg-4">
              <div class="form-group">
                <label>{"Engine Power (bhb)"}</label>
                <FormInputs
                  style={"form-control"}
                  intype={"text"}
                  value={data?.properties?.specifications?.power}
                  event={register}
                  placeholder={"Select Engine power"}
                  name={"power"}
                />
                {errors.power && <p>{errors.power.message}</p>}
              </div>
            </div>
            {!hideSpec?.cylinder?.includes(watch("fuel")) ? (
              <div class="col-sm-6 col-lg-4">
                <div class="form-group">
                  <label>{"Cylinders"}</label>
                  <SelectForm
                    style={"form-control"}
                    selectedOption={data?.properties?.specifications?.cylinders}
                    event={register}
                    name={"cylinders"}
                    values={upload_list.cylinder ? upload_list.cylinder : []}
                  />
                  {errors.cylinders && <p>{errors.cylinders.message}</p>}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div class="col-sm-6 col-lg-4">
              <div class="form-group">
                <label>{"Number of Doors"}*</label>
                <SelectForm
                  style={"form-control"}
                  selectedOption={data?.properties?.specifications?.doors}
                  event={register}
                  name={"doors"}
                  values={upload_list.doors ? upload_list.doors : []}
                />
                {errors.doors && <p>{errors.doors.message}</p>}
              </div>
            </div>
            <div class="col-sm-6 col-lg-4">
              <div class="form-group">
                <label>{"Number of Seats"}*</label>
                <SelectForm
                  style={"form-control"}
                  selectedOption={data?.properties?.specifications?.seats}
                  event={register}
                  name={"seats"}
                  values={upload_list.seats ? upload_list.seats : []}
                />
                {errors.seats && <p>{errors.seats.message}</p>}
              </div>
            </div>
          </div>
        </div>

        <div class="other_div other_div2">
          {isFieldRequired("features", page) && (
            <>
              <h3>{"Select Available Features"}</h3>
              <ul class="Select">
                {upload_list?.features?.map((item, key) => {
                  return (
                    <ListItem
                      key={key}
                      innerhtmlType={"html"}
                      value={
                        <>
                          <FormInputs
                            intype={"checkbox"}
                            checked={data?.properties?.features?.[item.value]}
                            event={register}
                            name={item.value}
                          />
                          {item?.name?.en}
                        </>
                      }
                    ></ListItem>
                  );
                })}
              </ul>
            </>
          )}
          <div class="row">
            {isFieldRequired("haveSomethingElse", page) && (
              <div class="col-sm-12">
                <h5>{"Have something else?"}</h5>
                <div class="ssp form-control">
                  <TagsInput
                    className="form-control"
                    value={selected}
                    name="feature"
                    onChange={setSelected}
                    onBlur={(e) => {
                      const tag = e?.target?.value;
                      if (!selected.includes(tag) && tag.length) {
                        setSelected([...selected, tag]);
                        document.getElementsByClassName("rti--input")[0].value =
                          "";
                      }
                    }}
                    placeHolder={"Type here"}
                  />
                  {errors.feature && <p>{errors.feature.message}</p>}
                </div>
              </div>
            )}
            {isFieldRequired("carDescription", page) && (
              <div class="col-sm-12">
                <div class="form-group">
                  <label>{"Car Description"}</label>
                  <TextAreaForm
                    style="form-control"
                    event={register}
                    value={data?.properties?.features?.carDescription?.["en"]}
                    name={"description"}
                    placeholder={"Write your description"}
                  />
                  {errors.description && <p>{errors.description.message}</p>}
                </div>
              </div>
            )}
            {isFieldRequired("brandNew", page) && (
              <div class="form-group mt-4">
                <h5>{"Brand new"}*</h5>
                <SelectForm
                  style={"form-control"}
                  // selectedOption={data?.properties?.specifications?.seats}
                  event={register}
                  name={"brandNew"}
                  values={upload_list.brandNew ? upload_list.brandNew : []}
                />
                {errors.brandNew && (
                  <p className="ms-3">{errors.brandNew.message}</p>
                )}
              </div>
            )}
          </div>
        </div>
        {isFieldRequired("plateCityPlateNumber", page) && (
          <div className="other_div other_div2">
            <div class="col-lg-12">
              <div class="form-group">
                <h5>{"Plate city"}</h5>
                <ReactSelectForm
                  selectedOption={watch("cityPlate")}
                  items={upload_list?.plateCities?.map((v) => ({
                    label: v?.name?.en,
                    value: v?.name?.en,
                  }))}
                  onChange={(op) => setValue("cityPlate", op?.value)}
                  showDropdownIcon
                  width={"100%"}
                />
                {errors.cityPlate && <p>{errors.cityPlate.message}</p>}
              </div>
            </div>
            <div class="col-lg-12 mt-4">
              <div class="form-group">
                <h5>{"Plate type"}</h5>
                <SelectForm
                  style={"form-control"}
                  event={register}
                  name={"plateType"}
                  selectedOption={data?.properties?.plateType}
                  values={upload_list?.plateTypes}
                />
                {errors.plateType && <p>{errors.plateType.message}</p>}
              </div>
            </div>
            <div className="d-lg-flex">
              <div class="col-lg-12  mt-4">
                <div class="form-group">
                  <h5>{"Plate Number"}</h5>
                  <FormInputs
                    style={"form-control"}
                    value={data?.properties?.registrationNumber}
                    event={register}
                    name={"registrationNumber"}
                  />
                  {errors?.registrationNumber && (
                    <p>{errors?.registrationNumber.message}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <Media
          setInputFileError={setInputFileError}
          inputFile={inputFile}
          register={register}
          InputFileError={InputFileError}
          uploadsInOrder={uploadsInOrder}
          setUploadsInOrder={setUploadsInOrder}
          setInputFile={setInputFile}
          // handleUploadDocument={handleUploadDocument}
          uploading={uploading}
          setUploading={setUploading}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          storedList={data?.uploads}
          type={6}
          adId={data?._id}
          hasOrder
        />
        {page == "SellYourCar" && (
          <SellPrice
            register={register}
            errors={errors}
            finance={upload_list.finance ? upload_list.finance : []}
            data={data}
            upload_list={upload_list}
            setValue={setValue}
          />
        )}
        {page == "RentYourCar" && (
          // <div className="row w-100">
          <RentPrice
            register={register}
            errors={errors}
            data={data}
            upload_list={upload_list}
            setValue={setValue}
          />
        )}

        {page == "Editvehicle" && (
          <SellPrice
            register={register}
            errors={errors}
            finance={upload_list.finance ? upload_list.finance : []}
            data={data}
            upload_list={upload_list}
            setValue={setValue}
          />
        )}

        <div class="text-center">
          <Button
            style="save_continue"
            type="submit"
            disabled={pageLoading}
            title={pageLoading ? "Please wait..." : "Save Changes"}
          />
        </div>
      </form>
    </div>
  );
}

export default EditAdvert;
