import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SendNotificationOption from "./sendNotificationOption";
import UsersOption from "./usersOption";
import { sendNotificationUser } from "../../../Reducer/sendNotification/sendNotificationSlice";
import ChecboxWithSpan from "../../Tools/CheckboxWithSpan";
import Button from "../../Tools/Button";
import SearchBar from "../../Tools/SearchBar";
import { all } from "axios";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useDidMountEffectWithDebounce } from "../../../data/hooks";
import SelectedAccounts from "./selectedAccounts";
const NotificationFilter = ({
  allFilter,
  setAllFilter,
  getName,
  selectUnselectUser,
}) => {
  const tabs = [
    {
      value: "brand",
      label: "Brands",
    },
    {
      value: "dealer",
      label: "Dealers",
    },
    {
      value: "user",
      label: "Users",
    },
  ];
  const [active, setActive] = React.useState("");
  const users = useSelector((state) => state.sendNotificationusers?.users);
  const submitting = useSelector(
    (state) => state.sendNotificationusers?.submitting
  );
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(sendNotificationUser({ userType: active }));
  }, [dispatch, active]);

  // const handleSearch = (data) => {
  //   dispatch(sendNotificationUser({ userType: active, name: data }));
  // };

  // useEffect(() => {
  //   dispatch(sendNotificationUser({ userType: active, name: searchTerm }));
  //   // console.log(searchTerm);
  // }, [searchTerm]);

  useDidMountEffectWithDebounce(
    () => {
      dispatch(sendNotificationUser({ userType: active, name: searchTerm }));
    },
    [searchTerm],
    500
  );

  console.log(allFilter);

  return (
    <div class="sideb">
      <div class="set">
        <h6>Send to</h6>
        <SendNotificationOption
          allFilter={allFilter}
          setAllFilter={setAllFilter}
        />
        <UsersOption allFilter={allFilter} setAllFilter={setAllFilter} />
      </div>
      <div class="set">
        <h5>Specific users</h5>
        <div className="" style={{ paddingInline: "15px" }}>
          {/* <Button
            style={`switch-button-case ${active == "user" && "activeButton"}`}
            title={"Private users"}
            onClick={() => setActive("user")}
          />
          <Button
            style={`switch-button-case ${active == "dealer" && "activeButton"}`}
            title={"Dealers"}
            onClick={() => setActive("dealer")}
          />
          <Button
            style={`switch-button-case ${active == "user" && "activeButton"}`}
            title={"Brands"}
            onClick={() => setActive("brand")}
          /> */}
          <div
            class="btn-group shadow-sm my-tabs"
            style={{ borderRadius: "4px", height: "30px" }}
            role="group"
            aria-label="Basic example"
          >
            {tabs.map((item) => {
              return (
                <button
                  disabled={submitting}
                  type="button"
                  class={`btn btn-light d-flex justify-content-center align-items-center ${
                    active == item?.value ? "activeButton" : ""
                  }`}
                  // style={{ width: "150px" }}
                  onClick={async () => {
                    if (active === item?.value) {
                      // localStorage.setItem(localStorageKeys.tab, "");
                      setActive("");
                    } else {
                      // localStorage.setItem(localStorageKeys.tab, item?.value);
                      setActive(item?.value);
                    }
                  }}
                >
                  {item?.label}
                </button>
              );
            })}
          </div>
        </div>
        <SearchBar
          placeholder={"Search Users"}
          // event={{ onclick: handleSearch }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <ul
          className="thinScroll"
          style={{ height: "300px", overflowY: "scroll" }}
        >
          {users &&
            users?.map?.((item, index) => {
              return (
                <li
                  style={{ padding: "0px 15px 0px" }}
                  className="d-flex align-items-center"
                >
                  <ChecboxWithSpan
                    id={item?.id}
                    // parentStyle={"checkcontainer"}
                    childStyle={"radiobtn"}
                    inType={"checkbox"}
                    inputStyle={"quality"}
                    // disabled={allFilter.sendTo.length > 0}
                    disabled={
                      item?.allowedToPushNotification === "false" || submitting
                    }
                    disabledText={"Notifications turned off by account holder"}
                    name={`user[${index}]`}
                    value={item?._id}
                    title={`${getName(item)}`}
                    checked={allFilter.users.includes(item?._id)}
                    onChange={(e) =>
                      selectUnselectUser(e.target.checked, e.target.value, item)
                    }
                  />
                </li>
              );
            })}
        </ul>
      </div>
      <SelectedAccounts
        className={"my-3 d-block d-xl-none"}
        allFilter={allFilter}
        setAllFilter={setAllFilter}
        selectUnselectUser={selectUnselectUser}
        getName={getName}
      />
    </div>
  );
};

export default NotificationFilter;
