import React from "react";
const FormInput = ({ style, placeholder, intype, event, name, value, inlineStyle }) => {
  return (
    <input
      class={style}
      value={value}
      type={intype}
      {...event(name)}
      placeholder={placeholder}
      style={inlineStyle}
    />
  );
};
export default FormInput;
