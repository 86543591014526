import React, { useEffect, useState } from "react";
import LiveTrafffic from "./liveTraffic";
import Metrics from "./metrics";
import KeyStats from "./keyStats";
import Overview from "./overview";
import { getDashboardData } from "../../../services/api";
import { formatDashboardData } from "./dataFormattingUtil";
import { toCamelCase } from "../../../data/utils";
import { setItemWithExpiry } from "../../../data/hooks";
// document.title = "dashboard";

const DashboardPage = () => {
  const [loading, setLoading] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [renderData, setRenderData] = useState({});
  const [storeData, setStoreData] = useState({});
  useEffect(() => {
    initiate();
    // getStatsData();
  }, []);

  const hasData = (data) => {
    return (
      (data?.overviewUser?.totalViews &&
        data?.overviewDealer?.totalViews &&
        true) ||
      false
    );
  };

  const initiate = async () => {
    try {
      const localData = JSON.parse(localStorage.getItem("dashboard-data"));

      const now = new Date();
      if (localData && localData.expiry) {
        // setData(JSON.parse(localData.value));
        console.log(localData);
        setStoreData(localData.value);
        if (now.getTime() > localData.expiry || !hasData(localData?.value)) {
          console.log("found-expired");
          setSyncing(true);
          await getStatsData();
          setSyncing(false);
        }
      } else {
        console.log("not-found");
        setLoading(true);
        await getStatsData();
        setLoading(false);
      }
    } catch (e) {
      localStorage.removeItem("dashboard-data");
      setSyncing(true);
      setLoading(true);
      await getStatsData();
      setSyncing(false);
      setLoading(true);
    }
  };
  const keyNames = {
    overview: "overview",
    brand: "overviewBrand",
    user: "overviewUser",
    dealer: "overviewDealer",
  };

  const getStatsData = async () => {
    const keys = ["user", "dealer", "brand", "overview"];
    let tempData = {};
    const promises = keys.map(async (key) => {
      try {
        let response = await getDashboardData({ filterName: key });
        const addRes = {
          [`${keyNames[key]}`]: response?.[key]?.overview || {},
          [`keyStats_${key}`]: response?.[key]?.keyStats || {},
        };
        tempData = { ...tempData, ...addRes };
        setStoreData((storeData) => ({ ...storeData, ...addRes }));
      } catch (error) {
        console.error(`Error fetching ${key} data:`, error);
      }
    });
    await Promise.allSettled(promises); // Wait for all API calls to complete
    // if (hasData(tempData)) {
    //   setItemWithExpiry("dashboard-data", tempData, 5);
    // }
  };

  useEffect(() => {
    const newRenderData = formatDashboardData(storeData);
    setRenderData(newRenderData);
  }, [storeData]);
  console.log(storeData);
  return (
    <>
      <Overview
        data={renderData?.overview}
        loading={loading}
        syncing={syncing}
      />
      <KeyStats data={renderData?.keyStats} loading={loading} />
      {/* <Metrics data={dashboardData?.metrics} loading={loading} /> */}
      {/* <LiveTrafffic /> */}
    </>
  );
};

export default DashboardPage;
